/**
 * jQuery functions for the single sign-on page
 */
(function($) {
	$(document).ready(function() {
		/**
		 * Select2 - the following code takes the <select> DOM element with a class of WSSOAccountName
		 * and changes it to select drop-down picker using the Select2 (https://select2.org/) library withs search support.
		 */
		$(".WSSOAccountName").select2({
			width: "resolve", // need to override the changed default
			placeholder: "Select your account ..."
		});
	});
})(jQuery);
