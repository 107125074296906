/*! Select2 4.0.13 | https://github.com/select2/select2/blob/master/LICENSE.md */
!(function (n) {
	"function" == typeof define && define.amd
		? define(["jquery"], n)
		: "object" == typeof module && module.exports
		? (module.exports = function (e, t) {
				return (
					void 0 === t &&
						(t =
							"undefined" != typeof window
								? require("jquery")
								: require("jquery")(e)),
					n(t),
					t
				);
		  })
		: n(jQuery);
})(function (u) {
	var e = (function () {
			if (u && u.fn && u.fn.select2 && u.fn.select2.amd) var e = u.fn.select2.amd;
			var t, n, r, h, o, s, f, g, m, v, y, _, i, a, b;
			function w(e, t) {
				return i.call(e, t);
			}
			function l(e, t) {
				var n,
					r,
					i,
					o,
					s,
					a,
					l,
					c,
					u,
					d,
					p,
					h = t && t.split("/"),
					f = y.map,
					g = (f && f["*"]) || {};
				if (e) {
					for (
						s = (e = e.split("/")).length - 1,
							y.nodeIdCompat && b.test(e[s]) && (e[s] = e[s].replace(b, "")),
							"." === e[0].charAt(0) && h && (e = h.slice(0, h.length - 1).concat(e)),
							u = 0;
						u < e.length;
						u++
					)
						if ("." === (p = e[u])) e.splice(u, 1), (u -= 1);
						else if (".." === p) {
							if (0 === u || (1 === u && ".." === e[2]) || ".." === e[u - 1])
								continue;
							0 < u && (e.splice(u - 1, 2), (u -= 2));
						}
					e = e.join("/");
				}
				if ((h || g) && f) {
					for (u = (n = e.split("/")).length; 0 < u; u -= 1) {
						if (((r = n.slice(0, u).join("/")), h))
							for (d = h.length; 0 < d; d -= 1)
								if ((i = (i = f[h.slice(0, d).join("/")]) && i[r])) {
									(o = i), (a = u);
									break;
								}
						if (o) break;
						!l && g && g[r] && ((l = g[r]), (c = u));
					}
					!o && l && ((o = l), (a = c)), o && (n.splice(0, a, o), (e = n.join("/")));
				}
				return e;
			}
			function A(t, n) {
				return function () {
					var e = a.call(arguments, 0);
					return (
						"string" != typeof e[0] && 1 === e.length && e.push(null),
						s.apply(h, e.concat([t, n]))
					);
				};
			}
			function x(t) {
				return function (e) {
					m[t] = e;
				};
			}
			function D(e) {
				if (w(v, e)) {
					var t = v[e];
					delete v[e], (_[e] = !0), o.apply(h, t);
				}
				if (!w(m, e) && !w(_, e)) throw new Error("No " + e);
				return m[e];
			}
			function c(e) {
				var t,
					n = e ? e.indexOf("!") : -1;
				return (
					-1 < n && ((t = e.substring(0, n)), (e = e.substring(n + 1, e.length))), [t, e]
				);
			}
			function S(e) {
				return e ? c(e) : [];
			}
			return (
				(e && e.requirejs) ||
					(e ? (n = e) : (e = {}),
					(m = {}),
					(v = {}),
					(y = {}),
					(_ = {}),
					(i = Object.prototype.hasOwnProperty),
					(a = [].slice),
					(b = /\.js$/),
					(f = function (e, t) {
						var n,
							r = c(e),
							i = r[0],
							o = t[1];
						return (
							(e = r[1]),
							i && (n = D((i = l(i, o)))),
							i
								? (e =
										n && n.normalize
											? n.normalize(
													e,
													(function (t) {
														return function (e) {
															return l(e, t);
														};
													})(o)
											  )
											: l(e, o))
								: ((i = (r = c((e = l(e, o))))[0]), (e = r[1]), i && (n = D(i))),
							{ f: i ? i + "!" + e : e, n: e, pr: i, p: n }
						);
					}),
					(g = {
						require: function (e) {
							return A(e);
						},
						exports: function (e) {
							var t = m[e];
							return void 0 !== t ? t : (m[e] = {});
						},
						module: function (e) {
							return {
								id: e,
								uri: "",
								exports: m[e],
								config: (function (e) {
									return function () {
										return (y && y.config && y.config[e]) || {};
									};
								})(e)
							};
						}
					}),
					(o = function (e, t, n, r) {
						var i,
							o,
							s,
							a,
							l,
							c,
							u,
							d = [],
							p = typeof n;
						if (((c = S((r = r || e))), "undefined" == p || "function" == p)) {
							for (
								t = !t.length && n.length ? ["require", "exports", "module"] : t,
									l = 0;
								l < t.length;
								l += 1
							)
								if ("require" === (o = (a = f(t[l], c)).f)) d[l] = g.require(e);
								else if ("exports" === o) (d[l] = g.exports(e)), (u = !0);
								else if ("module" === o) i = d[l] = g.module(e);
								else if (w(m, o) || w(v, o) || w(_, o)) d[l] = D(o);
								else {
									if (!a.p) throw new Error(e + " missing " + o);
									a.p.load(a.n, A(r, !0), x(o), {}), (d[l] = m[o]);
								}
							(s = n ? n.apply(m[e], d) : void 0),
								e &&
									(i && i.exports !== h && i.exports !== m[e]
										? (m[e] = i.exports)
										: (s === h && u) || (m[e] = s));
						} else e && (m[e] = n);
					}),
					(t = n = s = function (e, t, n, r, i) {
						if ("string" == typeof e) return g[e] ? g[e](t) : D(f(e, S(t)).f);
						if (!e.splice) {
							if (((y = e).deps && s(y.deps, y.callback), !t)) return;
							t.splice ? ((e = t), (t = n), (n = null)) : (e = h);
						}
						return (
							(t = t || function () {}),
							"function" == typeof n && ((n = r), (r = i)),
							r
								? o(h, e, t, n)
								: setTimeout(function () {
										o(h, e, t, n);
								  }, 4),
							s
						);
					}),
					(s.config = function (e) {
						return s(e);
					}),
					(t._defined = m),
					((r = function (e, t, n) {
						if ("string" != typeof e)
							throw new Error(
								"See almond README: incorrect module build, no module name"
							);
						t.splice || ((n = t), (t = [])), w(m, e) || w(v, e) || (v[e] = [e, t, n]);
					}).amd = { jQuery: !0 }),
					(e.requirejs = t),
					(e.require = n),
					(e.define = r)),
				e.define("almond", function () {}),
				e.define("jquery", [], function () {
					var e = u || $;
					return (
						null == e &&
							console &&
							console.error &&
							console.error(
								"Select2: An instance of jQuery or a jQuery-compatible library was not found. Make sure that you are including jQuery before Select2 on your web page."
							),
						e
					);
				}),
				e.define("select2/utils", ["jquery"], function (o) {
					var i = {};
					function u(e) {
						var t = e.prototype,
							n = [];
						for (var r in t) {
							"function" == typeof t[r] && "constructor" !== r && n.push(r);
						}
						return n;
					}
					(i.Extend = function (e, t) {
						var n = {}.hasOwnProperty;
						function r() {
							this.constructor = e;
						}
						for (var i in t) n.call(t, i) && (e[i] = t[i]);
						return (
							(r.prototype = t.prototype),
							(e.prototype = new r()),
							(e.__super__ = t.prototype),
							e
						);
					}),
						(i.Decorate = function (r, i) {
							var e = u(i),
								t = u(r);
							function o() {
								var e = Array.prototype.unshift,
									t = i.prototype.constructor.length,
									n = r.prototype.constructor;
								0 < t &&
									(e.call(arguments, r.prototype.constructor),
									(n = i.prototype.constructor)),
									n.apply(this, arguments);
							}
							(i.displayName = r.displayName),
								(o.prototype = new (function () {
									this.constructor = o;
								})());
							for (var n = 0; n < t.length; n++) {
								var s = t[n];
								o.prototype[s] = r.prototype[s];
							}
							function a(e) {
								var t = function () {};
								e in o.prototype && (t = o.prototype[e]);
								var n = i.prototype[e];
								return function () {
									return (
										Array.prototype.unshift.call(arguments, t),
										n.apply(this, arguments)
									);
								};
							}
							for (var l = 0; l < e.length; l++) {
								var c = e[l];
								o.prototype[c] = a(c);
							}
							return o;
						});
					function e() {
						this.listeners = {};
					}
					(e.prototype.on = function (e, t) {
						(this.listeners = this.listeners || {}),
							e in this.listeners
								? this.listeners[e].push(t)
								: (this.listeners[e] = [t]);
					}),
						(e.prototype.trigger = function (e) {
							var t = Array.prototype.slice,
								n = t.call(arguments, 1);
							(this.listeners = this.listeners || {}),
								null == n && (n = []),
								0 === n.length && n.push({}),
								(n[0]._type = e) in this.listeners &&
									this.invoke(this.listeners[e], t.call(arguments, 1)),
								"*" in this.listeners &&
									this.invoke(this.listeners["*"], arguments);
						}),
						(e.prototype.invoke = function (e, t) {
							for (var n = 0, r = e.length; n < r; n++) e[n].apply(this, t);
						}),
						(i.Observable = e),
						(i.generateChars = function (e) {
							for (var t = "", n = 0; n < e; n++) {
								t += Math.floor(36 * Math.random()).toString(36);
							}
							return t;
						}),
						(i.bind = function (e, t) {
							return function () {
								e.apply(t, arguments);
							};
						}),
						(i._convertData = function (e) {
							for (var t in e) {
								var n = t.split("-"),
									r = e;
								if (1 !== n.length) {
									for (var i = 0; i < n.length; i++) {
										var o = n[i];
										(o = o.substring(0, 1).toLowerCase() + o.substring(1)) in
											r || (r[o] = {}),
											i == n.length - 1 && (r[o] = e[t]),
											(r = r[o]);
									}
									delete e[t];
								}
							}
							return e;
						}),
						(i.hasScroll = function (e, t) {
							var n = o(t),
								r = t.style.overflowX,
								i = t.style.overflowY;
							return (
								(r !== i || ("hidden" !== i && "visible" !== i)) &&
								("scroll" === r ||
									"scroll" === i ||
									n.innerHeight() < t.scrollHeight ||
									n.innerWidth() < t.scrollWidth)
							);
						}),
						(i.escapeMarkup = function (e) {
							var t = {
								"\\": "&#92;",
								"&": "&amp;",
								"<": "&lt;",
								">": "&gt;",
								'"': "&quot;",
								"'": "&#39;",
								"/": "&#47;"
							};
							return "string" != typeof e
								? e
								: String(e).replace(/[&<>"'\/\\]/g, function (e) {
										return t[e];
								  });
						}),
						(i.appendMany = function (e, t) {
							if ("1.7" === o.fn.jquery.substr(0, 3)) {
								var n = o();
								o.map(t, function (e) {
									n = n.add(e);
								}),
									(t = n);
							}
							e.append(t);
						}),
						(i.__cache = {});
					var n = 0;
					return (
						(i.GetUniqueElementId = function (e) {
							var t = e.getAttribute("data-select2-id");
							return (
								null == t &&
									(e.id
										? ((t = e.id), e.setAttribute("data-select2-id", t))
										: (e.setAttribute("data-select2-id", ++n),
										  (t = n.toString()))),
								t
							);
						}),
						(i.StoreData = function (e, t, n) {
							var r = i.GetUniqueElementId(e);
							i.__cache[r] || (i.__cache[r] = {}), (i.__cache[r][t] = n);
						}),
						(i.GetData = function (e, t) {
							var n = i.GetUniqueElementId(e);
							return t
								? i.__cache[n] && null != i.__cache[n][t]
									? i.__cache[n][t]
									: o(e).data(t)
								: i.__cache[n];
						}),
						(i.RemoveData = function (e) {
							var t = i.GetUniqueElementId(e);
							null != i.__cache[t] && delete i.__cache[t],
								e.removeAttribute("data-select2-id");
						}),
						i
					);
				}),
				e.define("select2/results", ["jquery", "./utils"], function (h, f) {
					function r(e, t, n) {
						(this.$element = e),
							(this.data = n),
							(this.options = t),
							r.__super__.constructor.call(this);
					}
					return (
						f.Extend(r, f.Observable),
						(r.prototype.render = function () {
							var e = h('<ul class="select2-results__options" role="listbox"></ul>');
							return (
								this.options.get("multiple") &&
									e.attr("aria-multiselectable", "true"),
								(this.$results = e)
							);
						}),
						(r.prototype.clear = function () {
							this.$results.empty();
						}),
						(r.prototype.displayMessage = function (e) {
							var t = this.options.get("escapeMarkup");
							this.clear(), this.hideLoading();
							var n = h(
									'<li role="alert" aria-live="assertive" class="select2-results__option"></li>'
								),
								r = this.options.get("translations").get(e.message);
							n.append(t(r(e.args))),
								(n[0].className += " select2-results__message"),
								this.$results.append(n);
						}),
						(r.prototype.hideMessages = function () {
							this.$results.find(".select2-results__message").remove();
						}),
						(r.prototype.append = function (e) {
							this.hideLoading();
							var t = [];
							if (null != e.results && 0 !== e.results.length) {
								e.results = this.sort(e.results);
								for (var n = 0; n < e.results.length; n++) {
									var r = e.results[n],
										i = this.option(r);
									t.push(i);
								}
								this.$results.append(t);
							} else
								0 === this.$results.children().length &&
									this.trigger("results:message", { message: "noResults" });
						}),
						(r.prototype.position = function (e, t) {
							t.find(".select2-results").append(e);
						}),
						(r.prototype.sort = function (e) {
							return this.options.get("sorter")(e);
						}),
						(r.prototype.highlightFirstItem = function () {
							var e = this.$results.find(".select2-results__option[aria-selected]"),
								t = e.filter("[aria-selected=true]");
							0 < t.length
								? t.first().trigger("mouseenter")
								: e.first().trigger("mouseenter"),
								this.ensureHighlightVisible();
						}),
						(r.prototype.setClasses = function () {
							var t = this;
							this.data.current(function (e) {
								var r = h.map(e, function (e) {
									return e.id.toString();
								});
								t.$results
									.find(".select2-results__option[aria-selected]")
									.each(function () {
										var e = h(this),
											t = f.GetData(this, "data"),
											n = "" + t.id;
										(null != t.element && t.element.selected) ||
										(null == t.element && -1 < h.inArray(n, r))
											? e.attr("aria-selected", "true")
											: e.attr("aria-selected", "false");
									});
							});
						}),
						(r.prototype.showLoading = function (e) {
							this.hideLoading();
							var t = {
									disabled: !0,
									loading: !0,
									text: this.options.get("translations").get("searching")(e)
								},
								n = this.option(t);
							(n.className += " loading-results"), this.$results.prepend(n);
						}),
						(r.prototype.hideLoading = function () {
							this.$results.find(".loading-results").remove();
						}),
						(r.prototype.option = function (e) {
							var t = document.createElement("li");
							t.className = "select2-results__option";
							var n = { role: "option", "aria-selected": "false" },
								r =
									window.Element.prototype.matches ||
									window.Element.prototype.msMatchesSelector ||
									window.Element.prototype.webkitMatchesSelector;
							for (var i in (((null != e.element && r.call(e.element, ":disabled")) ||
								(null == e.element && e.disabled)) &&
								(delete n["aria-selected"], (n["aria-disabled"] = "true")),
							null == e.id && delete n["aria-selected"],
							null != e._resultId && (t.id = e._resultId),
							e.title && (t.title = e.title),
							e.children &&
								((n.role = "group"),
								(n["aria-label"] = e.text),
								delete n["aria-selected"]),
							n)) {
								var o = n[i];
								t.setAttribute(i, o);
							}
							if (e.children) {
								var s = h(t),
									a = document.createElement("strong");
								a.className = "select2-results__group";
								h(a);
								this.template(e, a);
								for (var l = [], c = 0; c < e.children.length; c++) {
									var u = e.children[c],
										d = this.option(u);
									l.push(d);
								}
								var p = h("<ul></ul>", {
									class:
										"select2-results__options select2-results__options--nested"
								});
								p.append(l), s.append(a), s.append(p);
							} else this.template(e, t);
							return f.StoreData(t, "data", e), t;
						}),
						(r.prototype.bind = function (t, e) {
							var l = this,
								n = t.id + "-results";
							this.$results.attr("id", n),
								t.on("results:all", function (e) {
									l.clear(),
										l.append(e.data),
										t.isOpen() && (l.setClasses(), l.highlightFirstItem());
								}),
								t.on("results:append", function (e) {
									l.append(e.data), t.isOpen() && l.setClasses();
								}),
								t.on("query", function (e) {
									l.hideMessages(), l.showLoading(e);
								}),
								t.on("select", function () {
									t.isOpen() &&
										(l.setClasses(),
										l.options.get("scrollAfterSelect") &&
											l.highlightFirstItem());
								}),
								t.on("unselect", function () {
									t.isOpen() &&
										(l.setClasses(),
										l.options.get("scrollAfterSelect") &&
											l.highlightFirstItem());
								}),
								t.on("open", function () {
									l.$results.attr("aria-expanded", "true"),
										l.$results.attr("aria-hidden", "false"),
										l.setClasses(),
										l.ensureHighlightVisible();
								}),
								t.on("close", function () {
									l.$results.attr("aria-expanded", "false"),
										l.$results.attr("aria-hidden", "true"),
										l.$results.removeAttr("aria-activedescendant");
								}),
								t.on("results:toggle", function () {
									var e = l.getHighlightedResults();
									0 !== e.length && e.trigger("mouseup");
								}),
								t.on("results:select", function () {
									var e = l.getHighlightedResults();
									if (0 !== e.length) {
										var t = f.GetData(e[0], "data");
										"true" == e.attr("aria-selected")
											? l.trigger("close", {})
											: l.trigger("select", { data: t });
									}
								}),
								t.on("results:previous", function () {
									var e = l.getHighlightedResults(),
										t = l.$results.find("[aria-selected]"),
										n = t.index(e);
									if (!(n <= 0)) {
										var r = n - 1;
										0 === e.length && (r = 0);
										var i = t.eq(r);
										i.trigger("mouseenter");
										var o = l.$results.offset().top,
											s = i.offset().top,
											a = l.$results.scrollTop() + (s - o);
										0 === r
											? l.$results.scrollTop(0)
											: s - o < 0 && l.$results.scrollTop(a);
									}
								}),
								t.on("results:next", function () {
									var e = l.getHighlightedResults(),
										t = l.$results.find("[aria-selected]"),
										n = t.index(e) + 1;
									if (!(n >= t.length)) {
										var r = t.eq(n);
										r.trigger("mouseenter");
										var i =
												l.$results.offset().top +
												l.$results.outerHeight(!1),
											o = r.offset().top + r.outerHeight(!1),
											s = l.$results.scrollTop() + o - i;
										0 === n
											? l.$results.scrollTop(0)
											: i < o && l.$results.scrollTop(s);
									}
								}),
								t.on("results:focus", function (e) {
									e.element.addClass("select2-results__option--highlighted");
								}),
								t.on("results:message", function (e) {
									l.displayMessage(e);
								}),
								h.fn.mousewheel &&
									this.$results.on("mousewheel", function (e) {
										var t = l.$results.scrollTop(),
											n = l.$results.get(0).scrollHeight - t + e.deltaY,
											r = 0 < e.deltaY && t - e.deltaY <= 0,
											i = e.deltaY < 0 && n <= l.$results.height();
										r
											? (l.$results.scrollTop(0),
											  e.preventDefault(),
											  e.stopPropagation())
											: i &&
											  (l.$results.scrollTop(
													l.$results.get(0).scrollHeight -
														l.$results.height()
											  ),
											  e.preventDefault(),
											  e.stopPropagation());
									}),
								this.$results.on(
									"mouseup",
									".select2-results__option[aria-selected]",
									function (e) {
										var t = h(this),
											n = f.GetData(this, "data");
										"true" !== t.attr("aria-selected")
											? l.trigger("select", { originalEvent: e, data: n })
											: l.options.get("multiple")
											? l.trigger("unselect", { originalEvent: e, data: n })
											: l.trigger("close", {});
									}
								),
								this.$results.on(
									"mouseenter",
									".select2-results__option[aria-selected]",
									function (e) {
										var t = f.GetData(this, "data");
										l
											.getHighlightedResults()
											.removeClass("select2-results__option--highlighted"),
											l.trigger("results:focus", {
												data: t,
												element: h(this)
											});
									}
								);
						}),
						(r.prototype.getHighlightedResults = function () {
							return this.$results.find(".select2-results__option--highlighted");
						}),
						(r.prototype.destroy = function () {
							this.$results.remove();
						}),
						(r.prototype.ensureHighlightVisible = function () {
							var e = this.getHighlightedResults();
							if (0 !== e.length) {
								var t = this.$results.find("[aria-selected]").index(e),
									n = this.$results.offset().top,
									r = e.offset().top,
									i = this.$results.scrollTop() + (r - n),
									o = r - n;
								(i -= 2 * e.outerHeight(!1)),
									t <= 2
										? this.$results.scrollTop(0)
										: (o > this.$results.outerHeight() || o < 0) &&
										  this.$results.scrollTop(i);
							}
						}),
						(r.prototype.template = function (e, t) {
							var n = this.options.get("templateResult"),
								r = this.options.get("escapeMarkup"),
								i = n(e, t);
							null == i
								? (t.style.display = "none")
								: "string" == typeof i
								? (t.innerHTML = r(i))
								: h(t).append(i);
						}),
						r
					);
				}),
				e.define("select2/keys", [], function () {
					return {
						BACKSPACE: 8,
						TAB: 9,
						ENTER: 13,
						SHIFT: 16,
						CTRL: 17,
						ALT: 18,
						ESC: 27,
						SPACE: 32,
						PAGE_UP: 33,
						PAGE_DOWN: 34,
						END: 35,
						HOME: 36,
						LEFT: 37,
						UP: 38,
						RIGHT: 39,
						DOWN: 40,
						DELETE: 46
					};
				}),
				e.define("select2/selection/base", ["jquery", "../utils", "../keys"], function (
					n,
					r,
					i
				) {
					function o(e, t) {
						(this.$element = e), (this.options = t), o.__super__.constructor.call(this);
					}
					return (
						r.Extend(o, r.Observable),
						(o.prototype.render = function () {
							var e = n(
								'<span class="select2-selection" role="combobox"  aria-haspopup="true" aria-expanded="false"></span>'
							);
							return (
								(this._tabindex = 0),
								null != r.GetData(this.$element[0], "old-tabindex")
									? (this._tabindex = r.GetData(this.$element[0], "old-tabindex"))
									: null != this.$element.attr("tabindex") &&
									  (this._tabindex = this.$element.attr("tabindex")),
								e.attr("title", this.$element.attr("title")),
								e.attr("tabindex", this._tabindex),
								e.attr("aria-disabled", "false"),
								(this.$selection = e)
							);
						}),
						(o.prototype.bind = function (e, t) {
							var n = this,
								r = e.id + "-results";
							(this.container = e),
								this.$selection.on("focus", function (e) {
									n.trigger("focus", e);
								}),
								this.$selection.on("blur", function (e) {
									n._handleBlur(e);
								}),
								this.$selection.on("keydown", function (e) {
									n.trigger("keypress", e),
										e.which === i.SPACE && e.preventDefault();
								}),
								e.on("results:focus", function (e) {
									n.$selection.attr("aria-activedescendant", e.data._resultId);
								}),
								e.on("selection:update", function (e) {
									n.update(e.data);
								}),
								e.on("open", function () {
									n.$selection.attr("aria-expanded", "true"),
										n.$selection.attr("aria-owns", r),
										n._attachCloseHandler(e);
								}),
								e.on("close", function () {
									n.$selection.attr("aria-expanded", "false"),
										n.$selection.removeAttr("aria-activedescendant"),
										n.$selection.removeAttr("aria-owns"),
										n.$selection.trigger("focus"),
										n._detachCloseHandler(e);
								}),
								e.on("enable", function () {
									n.$selection.attr("tabindex", n._tabindex),
										n.$selection.attr("aria-disabled", "false");
								}),
								e.on("disable", function () {
									n.$selection.attr("tabindex", "-1"),
										n.$selection.attr("aria-disabled", "true");
								});
						}),
						(o.prototype._handleBlur = function (e) {
							var t = this;
							window.setTimeout(function () {
								document.activeElement == t.$selection[0] ||
									n.contains(t.$selection[0], document.activeElement) ||
									t.trigger("blur", e);
							}, 1);
						}),
						(o.prototype._attachCloseHandler = function (e) {
							n(document.body).on("mousedown.select2." + e.id, function (e) {
								var t = n(e.target).closest(".select2");
								n(".select2.select2-container--open").each(function () {
									this != t[0] && r.GetData(this, "element").select2("close");
								});
							});
						}),
						(o.prototype._detachCloseHandler = function (e) {
							n(document.body).off("mousedown.select2." + e.id);
						}),
						(o.prototype.position = function (e, t) {
							t.find(".selection").append(e);
						}),
						(o.prototype.destroy = function () {
							this._detachCloseHandler(this.container);
						}),
						(o.prototype.update = function (e) {
							throw new Error(
								"The `update` method must be defined in child classes."
							);
						}),
						(o.prototype.isEnabled = function () {
							return !this.isDisabled();
						}),
						(o.prototype.isDisabled = function () {
							return this.options.get("disabled");
						}),
						o
					);
				}),
				e.define(
					"select2/selection/single",
					["jquery", "./base", "../utils", "../keys"],
					function (e, t, n, r) {
						function i() {
							i.__super__.constructor.apply(this, arguments);
						}
						return (
							n.Extend(i, t),
							(i.prototype.render = function () {
								var e = i.__super__.render.call(this);
								return (
									e.addClass("select2-selection--single"),
									e.html(
										'<span class="select2-selection__rendered"></span><span class="select2-selection__arrow" role="presentation"><b role="presentation"></b></span>'
									),
									e
								);
							}),
							(i.prototype.bind = function (t, e) {
								var n = this;
								i.__super__.bind.apply(this, arguments);
								var r = t.id + "-container";
								this.$selection
									.find(".select2-selection__rendered")
									.attr("id", r)
									.attr("role", "textbox")
									.attr("aria-readonly", "true"),
									this.$selection.attr("aria-labelledby", r),
									this.$selection.on("mousedown", function (e) {
										1 === e.which && n.trigger("toggle", { originalEvent: e });
									}),
									this.$selection.on("focus", function (e) {}),
									this.$selection.on("blur", function (e) {}),
									t.on("focus", function (e) {
										t.isOpen() || n.$selection.trigger("focus");
									});
							}),
							(i.prototype.clear = function () {
								var e = this.$selection.find(".select2-selection__rendered");
								e.empty(), e.removeAttr("title");
							}),
							(i.prototype.display = function (e, t) {
								var n = this.options.get("templateSelection");
								return this.options.get("escapeMarkup")(n(e, t));
							}),
							(i.prototype.selectionContainer = function () {
								return e("<span></span>");
							}),
							(i.prototype.update = function (e) {
								if (0 !== e.length) {
									var t = e[0],
										n = this.$selection.find(".select2-selection__rendered"),
										r = this.display(t, n);
									n.empty().append(r);
									var i = t.title || t.text;
									i ? n.attr("title", i) : n.removeAttr("title");
								} else this.clear();
							}),
							i
						);
					}
				),
				e.define("select2/selection/multiple", ["jquery", "./base", "../utils"], function (
					i,
					e,
					l
				) {
					function n(e, t) {
						n.__super__.constructor.apply(this, arguments);
					}
					return (
						l.Extend(n, e),
						(n.prototype.render = function () {
							var e = n.__super__.render.call(this);
							return (
								e.addClass("select2-selection--multiple"),
								e.html('<ul class="select2-selection__rendered"></ul>'),
								e
							);
						}),
						(n.prototype.bind = function (e, t) {
							var r = this;
							n.__super__.bind.apply(this, arguments),
								this.$selection.on("click", function (e) {
									r.trigger("toggle", { originalEvent: e });
								}),
								this.$selection.on(
									"click",
									".select2-selection__choice__remove",
									function (e) {
										if (!r.isDisabled()) {
											var t = i(this).parent(),
												n = l.GetData(t[0], "data");
											r.trigger("unselect", { originalEvent: e, data: n });
										}
									}
								);
						}),
						(n.prototype.clear = function () {
							var e = this.$selection.find(".select2-selection__rendered");
							e.empty(), e.removeAttr("title");
						}),
						(n.prototype.display = function (e, t) {
							var n = this.options.get("templateSelection");
							return this.options.get("escapeMarkup")(n(e, t));
						}),
						(n.prototype.selectionContainer = function () {
							return i(
								'<li class="select2-selection__choice"><span class="select2-selection__choice__remove" role="presentation">&times;</span></li>'
							);
						}),
						(n.prototype.update = function (e) {
							if ((this.clear(), 0 !== e.length)) {
								for (var t = [], n = 0; n < e.length; n++) {
									var r = e[n],
										i = this.selectionContainer(),
										o = this.display(r, i);
									i.append(o);
									var s = r.title || r.text;
									s && i.attr("title", s),
										l.StoreData(i[0], "data", r),
										t.push(i);
								}
								var a = this.$selection.find(".select2-selection__rendered");
								l.appendMany(a, t);
							}
						}),
						n
					);
				}),
				e.define("select2/selection/placeholder", ["../utils"], function (e) {
					function t(e, t, n) {
						(this.placeholder = this.normalizePlaceholder(n.get("placeholder"))),
							e.call(this, t, n);
					}
					return (
						(t.prototype.normalizePlaceholder = function (e, t) {
							return "string" == typeof t && (t = { id: "", text: t }), t;
						}),
						(t.prototype.createPlaceholder = function (e, t) {
							var n = this.selectionContainer();
							return (
								n.html(this.display(t)),
								n
									.addClass("select2-selection__placeholder")
									.removeClass("select2-selection__choice"),
								n
							);
						}),
						(t.prototype.update = function (e, t) {
							var n = 1 == t.length && t[0].id != this.placeholder.id;
							if (1 < t.length || n) return e.call(this, t);
							this.clear();
							var r = this.createPlaceholder(this.placeholder);
							this.$selection.find(".select2-selection__rendered").append(r);
						}),
						t
					);
				}),
				e.define(
					"select2/selection/allowClear",
					["jquery", "../keys", "../utils"],
					function (i, r, a) {
						function e() {}
						return (
							(e.prototype.bind = function (e, t, n) {
								var r = this;
								e.call(this, t, n),
									null == this.placeholder &&
										this.options.get("debug") &&
										window.console &&
										console.error &&
										console.error(
											"Select2: The `allowClear` option should be used in combination with the `placeholder` option."
										),
									this.$selection.on(
										"mousedown",
										".select2-selection__clear",
										function (e) {
											r._handleClear(e);
										}
									),
									t.on("keypress", function (e) {
										r._handleKeyboardClear(e, t);
									});
							}),
							(e.prototype._handleClear = function (e, t) {
								if (!this.isDisabled()) {
									var n = this.$selection.find(".select2-selection__clear");
									if (0 !== n.length) {
										t.stopPropagation();
										var r = a.GetData(n[0], "data"),
											i = this.$element.val();
										this.$element.val(this.placeholder.id);
										var o = { data: r };
										if ((this.trigger("clear", o), o.prevented))
											this.$element.val(i);
										else {
											for (var s = 0; s < r.length; s++)
												if (
													((o = { data: r[s] }),
													this.trigger("unselect", o),
													o.prevented)
												)
													return void this.$element.val(i);
											this.$element.trigger("input").trigger("change"),
												this.trigger("toggle", {});
										}
									}
								}
							}),
							(e.prototype._handleKeyboardClear = function (e, t, n) {
								n.isOpen() ||
									(t.which != r.DELETE && t.which != r.BACKSPACE) ||
									this._handleClear(t);
							}),
							(e.prototype.update = function (e, t) {
								if (
									(e.call(this, t),
									!(
										0 <
											this.$selection.find(".select2-selection__placeholder")
												.length || 0 === t.length
									))
								) {
									var n = this.options.get("translations").get("removeAllItems"),
										r = i(
											'<span class="select2-selection__clear" title="' +
												n() +
												'">&times;</span>'
										);
									a.StoreData(r[0], "data", t),
										this.$selection
											.find(".select2-selection__rendered")
											.prepend(r);
								}
							}),
							e
						);
					}
				),
				e.define("select2/selection/search", ["jquery", "../utils", "../keys"], function (
					r,
					a,
					l
				) {
					function e(e, t, n) {
						e.call(this, t, n);
					}
					return (
						(e.prototype.render = function (e) {
							var t = r(
								'<li class="select2-search select2-search--inline"><input class="select2-search__field" type="search" tabindex="-1" autocomplete="off" autocorrect="off" autocapitalize="none" spellcheck="false" role="searchbox" aria-autocomplete="list" /></li>'
							);
							(this.$searchContainer = t), (this.$search = t.find("input"));
							var n = e.call(this);
							return this._transferTabIndex(), n;
						}),
						(e.prototype.bind = function (e, t, n) {
							var r = this,
								i = t.id + "-results";
							e.call(this, t, n),
								t.on("open", function () {
									r.$search.attr("aria-controls", i), r.$search.trigger("focus");
								}),
								t.on("close", function () {
									r.$search.val(""),
										r.$search.removeAttr("aria-controls"),
										r.$search.removeAttr("aria-activedescendant"),
										r.$search.trigger("focus");
								}),
								t.on("enable", function () {
									r.$search.prop("disabled", !1), r._transferTabIndex();
								}),
								t.on("disable", function () {
									r.$search.prop("disabled", !0);
								}),
								t.on("focus", function (e) {
									r.$search.trigger("focus");
								}),
								t.on("results:focus", function (e) {
									e.data._resultId
										? r.$search.attr("aria-activedescendant", e.data._resultId)
										: r.$search.removeAttr("aria-activedescendant");
								}),
								this.$selection.on("focusin", ".select2-search--inline", function (
									e
								) {
									r.trigger("focus", e);
								}),
								this.$selection.on("focusout", ".select2-search--inline", function (
									e
								) {
									r._handleBlur(e);
								}),
								this.$selection.on("keydown", ".select2-search--inline", function (
									e
								) {
									if (
										(e.stopPropagation(),
										r.trigger("keypress", e),
										(r._keyUpPrevented = e.isDefaultPrevented()),
										e.which === l.BACKSPACE && "" === r.$search.val())
									) {
										var t = r.$searchContainer.prev(
											".select2-selection__choice"
										);
										if (0 < t.length) {
											var n = a.GetData(t[0], "data");
											r.searchRemoveChoice(n), e.preventDefault();
										}
									}
								}),
								this.$selection.on("click", ".select2-search--inline", function (
									e
								) {
									r.$search.val() && e.stopPropagation();
								});
							var o = document.documentMode,
								s = o && o <= 11;
							this.$selection.on(
								"input.searchcheck",
								".select2-search--inline",
								function (e) {
									s
										? r.$selection.off("input.search input.searchcheck")
										: r.$selection.off("keyup.search");
								}
							),
								this.$selection.on(
									"keyup.search input.search",
									".select2-search--inline",
									function (e) {
										if (s && "input" === e.type)
											r.$selection.off("input.search input.searchcheck");
										else {
											var t = e.which;
											t != l.SHIFT &&
												t != l.CTRL &&
												t != l.ALT &&
												t != l.TAB &&
												r.handleSearch(e);
										}
									}
								);
						}),
						(e.prototype._transferTabIndex = function (e) {
							this.$search.attr("tabindex", this.$selection.attr("tabindex")),
								this.$selection.attr("tabindex", "-1");
						}),
						(e.prototype.createPlaceholder = function (e, t) {
							this.$search.attr("placeholder", t.text);
						}),
						(e.prototype.update = function (e, t) {
							var n = this.$search[0] == document.activeElement;
							this.$search.attr("placeholder", ""),
								e.call(this, t),
								this.$selection
									.find(".select2-selection__rendered")
									.append(this.$searchContainer),
								this.resizeSearch(),
								n && this.$search.trigger("focus");
						}),
						(e.prototype.handleSearch = function () {
							if ((this.resizeSearch(), !this._keyUpPrevented)) {
								var e = this.$search.val();
								this.trigger("query", { term: e });
							}
							this._keyUpPrevented = !1;
						}),
						(e.prototype.searchRemoveChoice = function (e, t) {
							this.trigger("unselect", { data: t }),
								this.$search.val(t.text),
								this.handleSearch();
						}),
						(e.prototype.resizeSearch = function () {
							this.$search.css("width", "25px");
							var e = "";
							"" !== this.$search.attr("placeholder")
								? (e = this.$selection.find(".select2-selection__rendered").width())
								: (e = 0.75 * (this.$search.val().length + 1) + "em");
							this.$search.css("width", e);
						}),
						e
					);
				}),
				e.define("select2/selection/eventRelay", ["jquery"], function (s) {
					function e() {}
					return (
						(e.prototype.bind = function (e, t, n) {
							var r = this,
								i = [
									"open",
									"opening",
									"close",
									"closing",
									"select",
									"selecting",
									"unselect",
									"unselecting",
									"clear",
									"clearing"
								],
								o = ["opening", "closing", "selecting", "unselecting", "clearing"];
							e.call(this, t, n),
								t.on("*", function (e, t) {
									if (-1 !== s.inArray(e, i)) {
										t = t || {};
										var n = s.Event("select2:" + e, { params: t });
										r.$element.trigger(n),
											-1 !== s.inArray(e, o) &&
												(t.prevented = n.isDefaultPrevented());
									}
								});
						}),
						e
					);
				}),
				e.define("select2/translation", ["jquery", "require"], function (t, n) {
					function r(e) {
						this.dict = e || {};
					}
					return (
						(r.prototype.all = function () {
							return this.dict;
						}),
						(r.prototype.get = function (e) {
							return this.dict[e];
						}),
						(r.prototype.extend = function (e) {
							this.dict = t.extend({}, e.all(), this.dict);
						}),
						(r._cache = {}),
						(r.loadPath = function (e) {
							if (!(e in r._cache)) {
								var t = n(e);
								r._cache[e] = t;
							}
							return new r(r._cache[e]);
						}),
						r
					);
				}),
				// e.define("select2/diacritics", [], function () {
				// 	return {
				// 		"Ⓐ": "A",
				// 		Ａ: "A",
				// 		À: "A",
				// 		Á: "A",
				// 		Â: "A",
				// 		Ầ: "A",
				// 		Ấ: "A",
				// 		Ẫ: "A",
				// 		Ẩ: "A",
				// 		Ã: "A",
				// 		Ā: "A",
				// 		Ă: "A",
				// 		Ằ: "A",
				// 		Ắ: "A",
				// 		Ẵ: "A",
				// 		Ẳ: "A",
				// 		Ȧ: "A",
				// 		Ǡ: "A",
				// 		Ä: "A",
				// 		Ǟ: "A",
				// 		Ả: "A",
				// 		Å: "A",
				// 		Ǻ: "A",
				// 		Ǎ: "A",
				// 		Ȁ: "A",
				// 		Ȃ: "A",
				// 		Ạ: "A",
				// 		Ậ: "A",
				// 		Ặ: "A",
				// 		Ḁ: "A",
				// 		Ą: "A",
				// 		Ⱥ: "A",
				// 		Ɐ: "A",
				// 		Ꜳ: "AA",
				// 		Æ: "AE",
				// 		Ǽ: "AE",
				// 		Ǣ: "AE",
				// 		Ꜵ: "AO",
				// 		Ꜷ: "AU",
				// 		Ꜹ: "AV",
				// 		Ꜻ: "AV",
				// 		Ꜽ: "AY",
				// 		"Ⓑ": "B",
				// 		Ｂ: "B",
				// 		Ḃ: "B",
				// 		Ḅ: "B",
				// 		Ḇ: "B",
				// 		Ƀ: "B",
				// 		Ƃ: "B",
				// 		Ɓ: "B",
				// 		"Ⓒ": "C",
				// 		Ｃ: "C",
				// 		Ć: "C",
				// 		Ĉ: "C",
				// 		Ċ: "C",
				// 		Č: "C",
				// 		Ç: "C",
				// 		Ḉ: "C",
				// 		Ƈ: "C",
				// 		Ȼ: "C",
				// 		Ꜿ: "C",
				// 		"Ⓓ": "D",
				// 		Ｄ: "D",
				// 		Ḋ: "D",
				// 		Ď: "D",
				// 		Ḍ: "D",
				// 		Ḑ: "D",
				// 		Ḓ: "D",
				// 		Ḏ: "D",
				// 		Đ: "D",
				// 		Ƌ: "D",
				// 		Ɗ: "D",
				// 		Ɖ: "D",
				// 		Ꝺ: "D",
				// 		Ǳ: "DZ",
				// 		Ǆ: "DZ",
				// 		ǲ: "Dz",
				// 		ǅ: "Dz",
				// 		"Ⓔ": "E",
				// 		Ｅ: "E",
				// 		È: "E",
				// 		É: "E",
				// 		Ê: "E",
				// 		Ề: "E",
				// 		Ế: "E",
				// 		Ễ: "E",
				// 		Ể: "E",
				// 		Ẽ: "E",
				// 		Ē: "E",
				// 		Ḕ: "E",
				// 		Ḗ: "E",
				// 		Ĕ: "E",
				// 		Ė: "E",
				// 		Ë: "E",
				// 		Ẻ: "E",
				// 		Ě: "E",
				// 		Ȅ: "E",
				// 		Ȇ: "E",
				// 		Ẹ: "E",
				// 		Ệ: "E",
				// 		Ȩ: "E",
				// 		Ḝ: "E",
				// 		Ę: "E",
				// 		Ḙ: "E",
				// 		Ḛ: "E",
				// 		Ɛ: "E",
				// 		Ǝ: "E",
				// 		"Ⓕ": "F",
				// 		Ｆ: "F",
				// 		Ḟ: "F",
				// 		Ƒ: "F",
				// 		Ꝼ: "F",
				// 		"Ⓖ": "G",
				// 		Ｇ: "G",
				// 		Ǵ: "G",
				// 		Ĝ: "G",
				// 		Ḡ: "G",
				// 		Ğ: "G",
				// 		Ġ: "G",
				// 		Ǧ: "G",
				// 		Ģ: "G",
				// 		Ǥ: "G",
				// 		Ɠ: "G",
				// 		Ꞡ: "G",
				// 		Ᵹ: "G",
				// 		Ꝿ: "G",
				// 		"Ⓗ": "H",
				// 		Ｈ: "H",
				// 		Ĥ: "H",
				// 		Ḣ: "H",
				// 		Ḧ: "H",
				// 		Ȟ: "H",
				// 		Ḥ: "H",
				// 		Ḩ: "H",
				// 		Ḫ: "H",
				// 		Ħ: "H",
				// 		Ⱨ: "H",
				// 		Ⱶ: "H",
				// 		Ɥ: "H",
				// 		"Ⓘ": "I",
				// 		Ｉ: "I",
				// 		Ì: "I",
				// 		Í: "I",
				// 		Î: "I",
				// 		Ĩ: "I",
				// 		Ī: "I",
				// 		Ĭ: "I",
				// 		İ: "I",
				// 		Ï: "I",
				// 		Ḯ: "I",
				// 		Ỉ: "I",
				// 		Ǐ: "I",
				// 		Ȉ: "I",
				// 		Ȋ: "I",
				// 		Ị: "I",
				// 		Į: "I",
				// 		Ḭ: "I",
				// 		Ɨ: "I",
				// 		"Ⓙ": "J",
				// 		Ｊ: "J",
				// 		Ĵ: "J",
				// 		Ɉ: "J",
				// 		"Ⓚ": "K",
				// 		Ｋ: "K",
				// 		Ḱ: "K",
				// 		Ǩ: "K",
				// 		Ḳ: "K",
				// 		Ķ: "K",
				// 		Ḵ: "K",
				// 		Ƙ: "K",
				// 		Ⱪ: "K",
				// 		Ꝁ: "K",
				// 		Ꝃ: "K",
				// 		Ꝅ: "K",
				// 		Ꞣ: "K",
				// 		"Ⓛ": "L",
				// 		Ｌ: "L",
				// 		Ŀ: "L",
				// 		Ĺ: "L",
				// 		Ľ: "L",
				// 		Ḷ: "L",
				// 		Ḹ: "L",
				// 		Ļ: "L",
				// 		Ḽ: "L",
				// 		Ḻ: "L",
				// 		Ł: "L",
				// 		Ƚ: "L",
				// 		Ɫ: "L",
				// 		Ⱡ: "L",
				// 		Ꝉ: "L",
				// 		Ꝇ: "L",
				// 		Ꞁ: "L",
				// 		Ǉ: "LJ",
				// 		ǈ: "Lj",
				// 		"Ⓜ": "M",
				// 		Ｍ: "M",
				// 		Ḿ: "M",
				// 		Ṁ: "M",
				// 		Ṃ: "M",
				// 		Ɱ: "M",
				// 		Ɯ: "M",
				// 		"Ⓝ": "N",
				// 		Ｎ: "N",
				// 		Ǹ: "N",
				// 		Ń: "N",
				// 		Ñ: "N",
				// 		Ṅ: "N",
				// 		Ň: "N",
				// 		Ṇ: "N",
				// 		Ņ: "N",
				// 		Ṋ: "N",
				// 		Ṉ: "N",
				// 		Ƞ: "N",
				// 		Ɲ: "N",
				// 		Ꞑ: "N",
				// 		Ꞥ: "N",
				// 		Ǌ: "NJ",
				// 		ǋ: "Nj",
				// 		"Ⓞ": "O",
				// 		Ｏ: "O",
				// 		Ò: "O",
				// 		Ó: "O",
				// 		Ô: "O",
				// 		Ồ: "O",
				// 		Ố: "O",
				// 		Ỗ: "O",
				// 		Ổ: "O",
				// 		Õ: "O",
				// 		Ṍ: "O",
				// 		Ȭ: "O",
				// 		Ṏ: "O",
				// 		Ō: "O",
				// 		Ṑ: "O",
				// 		Ṓ: "O",
				// 		Ŏ: "O",
				// 		Ȯ: "O",
				// 		Ȱ: "O",
				// 		Ö: "O",
				// 		Ȫ: "O",
				// 		Ỏ: "O",
				// 		Ő: "O",
				// 		Ǒ: "O",
				// 		Ȍ: "O",
				// 		Ȏ: "O",
				// 		Ơ: "O",
				// 		Ờ: "O",
				// 		Ớ: "O",
				// 		Ỡ: "O",
				// 		Ở: "O",
				// 		Ợ: "O",
				// 		Ọ: "O",
				// 		Ộ: "O",
				// 		Ǫ: "O",
				// 		Ǭ: "O",
				// 		Ø: "O",
				// 		Ǿ: "O",
				// 		Ɔ: "O",
				// 		Ɵ: "O",
				// 		Ꝋ: "O",
				// 		Ꝍ: "O",
				// 		Œ: "OE",
				// 		Ƣ: "OI",
				// 		Ꝏ: "OO",
				// 		Ȣ: "OU",
				// 		"Ⓟ": "P",
				// 		Ｐ: "P",
				// 		Ṕ: "P",
				// 		Ṗ: "P",
				// 		Ƥ: "P",
				// 		Ᵽ: "P",
				// 		Ꝑ: "P",
				// 		Ꝓ: "P",
				// 		Ꝕ: "P",
				// 		"Ⓠ": "Q",
				// 		Ｑ: "Q",
				// 		Ꝗ: "Q",
				// 		Ꝙ: "Q",
				// 		Ɋ: "Q",
				// 		"Ⓡ": "R",
				// 		Ｒ: "R",
				// 		Ŕ: "R",
				// 		Ṙ: "R",
				// 		Ř: "R",
				// 		Ȑ: "R",
				// 		Ȓ: "R",
				// 		Ṛ: "R",
				// 		Ṝ: "R",
				// 		Ŗ: "R",
				// 		Ṟ: "R",
				// 		Ɍ: "R",
				// 		Ɽ: "R",
				// 		Ꝛ: "R",
				// 		Ꞧ: "R",
				// 		Ꞃ: "R",
				// 		"Ⓢ": "S",
				// 		Ｓ: "S",
				// 		ẞ: "S",
				// 		Ś: "S",
				// 		Ṥ: "S",
				// 		Ŝ: "S",
				// 		Ṡ: "S",
				// 		Š: "S",
				// 		Ṧ: "S",
				// 		Ṣ: "S",
				// 		Ṩ: "S",
				// 		Ș: "S",
				// 		Ş: "S",
				// 		Ȿ: "S",
				// 		Ꞩ: "S",
				// 		Ꞅ: "S",
				// 		"Ⓣ": "T",
				// 		Ｔ: "T",
				// 		Ṫ: "T",
				// 		Ť: "T",
				// 		Ṭ: "T",
				// 		Ț: "T",
				// 		Ţ: "T",
				// 		Ṱ: "T",
				// 		Ṯ: "T",
				// 		Ŧ: "T",
				// 		Ƭ: "T",
				// 		Ʈ: "T",
				// 		Ⱦ: "T",
				// 		Ꞇ: "T",
				// 		Ꜩ: "TZ",
				// 		"Ⓤ": "U",
				// 		Ｕ: "U",
				// 		Ù: "U",
				// 		Ú: "U",
				// 		Û: "U",
				// 		Ũ: "U",
				// 		Ṹ: "U",
				// 		Ū: "U",
				// 		Ṻ: "U",
				// 		Ŭ: "U",
				// 		Ü: "U",
				// 		Ǜ: "U",
				// 		Ǘ: "U",
				// 		Ǖ: "U",
				// 		Ǚ: "U",
				// 		Ủ: "U",
				// 		Ů: "U",
				// 		Ű: "U",
				// 		Ǔ: "U",
				// 		Ȕ: "U",
				// 		Ȗ: "U",
				// 		Ư: "U",
				// 		Ừ: "U",
				// 		Ứ: "U",
				// 		Ữ: "U",
				// 		Ử: "U",
				// 		Ự: "U",
				// 		Ụ: "U",
				// 		Ṳ: "U",
				// 		Ų: "U",
				// 		Ṷ: "U",
				// 		Ṵ: "U",
				// 		Ʉ: "U",
				// 		"Ⓥ": "V",
				// 		Ｖ: "V",
				// 		Ṽ: "V",
				// 		Ṿ: "V",
				// 		Ʋ: "V",
				// 		Ꝟ: "V",
				// 		Ʌ: "V",
				// 		Ꝡ: "VY",
				// 		"Ⓦ": "W",
				// 		Ｗ: "W",
				// 		Ẁ: "W",
				// 		Ẃ: "W",
				// 		Ŵ: "W",
				// 		Ẇ: "W",
				// 		Ẅ: "W",
				// 		Ẉ: "W",
				// 		Ⱳ: "W",
				// 		"Ⓧ": "X",
				// 		Ｘ: "X",
				// 		Ẋ: "X",
				// 		Ẍ: "X",
				// 		"Ⓨ": "Y",
				// 		Ｙ: "Y",
				// 		Ỳ: "Y",
				// 		Ý: "Y",
				// 		Ŷ: "Y",
				// 		Ỹ: "Y",
				// 		Ȳ: "Y",
				// 		Ẏ: "Y",
				// 		Ÿ: "Y",
				// 		Ỷ: "Y",
				// 		Ỵ: "Y",
				// 		Ƴ: "Y",
				// 		Ɏ: "Y",
				// 		Ỿ: "Y",
				// 		"Ⓩ": "Z",
				// 		Ｚ: "Z",
				// 		Ź: "Z",
				// 		Ẑ: "Z",
				// 		Ż: "Z",
				// 		Ž: "Z",
				// 		Ẓ: "Z",
				// 		Ẕ: "Z",
				// 		Ƶ: "Z",
				// 		Ȥ: "Z",
				// 		Ɀ: "Z",
				// 		Ⱬ: "Z",
				// 		Ꝣ: "Z",
				// 		"ⓐ": "a",
				// 		ａ: "a",
				// 		ẚ: "a",
				// 		à: "a",
				// 		á: "a",
				// 		â: "a",
				// 		ầ: "a",
				// 		ấ: "a",
				// 		ẫ: "a",
				// 		ẩ: "a",
				// 		ã: "a",
				// 		ā: "a",
				// 		ă: "a",
				// 		ằ: "a",
				// 		ắ: "a",
				// 		ẵ: "a",
				// 		ẳ: "a",
				// 		ȧ: "a",
				// 		ǡ: "a",
				// 		ä: "a",
				// 		ǟ: "a",
				// 		ả: "a",
				// 		å: "a",
				// 		ǻ: "a",
				// 		ǎ: "a",
				// 		ȁ: "a",
				// 		ȃ: "a",
				// 		ạ: "a",
				// 		ậ: "a",
				// 		ặ: "a",
				// 		ḁ: "a",
				// 		ą: "a",
				// 		ⱥ: "a",
				// 		ɐ: "a",
				// 		ꜳ: "aa",
				// 		æ: "ae",
				// 		ǽ: "ae",
				// 		ǣ: "ae",
				// 		ꜵ: "ao",
				// 		ꜷ: "au",
				// 		ꜹ: "av",
				// 		ꜻ: "av",
				// 		ꜽ: "ay",
				// 		"ⓑ": "b",
				// 		ｂ: "b",
				// 		ḃ: "b",
				// 		ḅ: "b",
				// 		ḇ: "b",
				// 		ƀ: "b",
				// 		ƃ: "b",
				// 		ɓ: "b",
				// 		"ⓒ": "c",
				// 		ｃ: "c",
				// 		ć: "c",
				// 		ĉ: "c",
				// 		ċ: "c",
				// 		č: "c",
				// 		ç: "c",
				// 		ḉ: "c",
				// 		ƈ: "c",
				// 		ȼ: "c",
				// 		ꜿ: "c",
				// 		ↄ: "c",
				// 		"ⓓ": "d",
				// 		ｄ: "d",
				// 		ḋ: "d",
				// 		ď: "d",
				// 		ḍ: "d",
				// 		ḑ: "d",
				// 		ḓ: "d",
				// 		ḏ: "d",
				// 		đ: "d",
				// 		ƌ: "d",
				// 		ɖ: "d",
				// 		ɗ: "d",
				// 		ꝺ: "d",
				// 		ǳ: "dz",
				// 		ǆ: "dz",
				// 		"ⓔ": "e",
				// 		ｅ: "e",
				// 		è: "e",
				// 		é: "e",
				// 		ê: "e",
				// 		ề: "e",
				// 		ế: "e",
				// 		ễ: "e",
				// 		ể: "e",
				// 		ẽ: "e",
				// 		ē: "e",
				// 		ḕ: "e",
				// 		ḗ: "e",
				// 		ĕ: "e",
				// 		ė: "e",
				// 		ë: "e",
				// 		ẻ: "e",
				// 		ě: "e",
				// 		ȅ: "e",
				// 		ȇ: "e",
				// 		ẹ: "e",
				// 		ệ: "e",
				// 		ȩ: "e",
				// 		ḝ: "e",
				// 		ę: "e",
				// 		ḙ: "e",
				// 		ḛ: "e",
				// 		ɇ: "e",
				// 		ɛ: "e",
				// 		ǝ: "e",
				// 		"ⓕ": "f",
				// 		ｆ: "f",
				// 		ḟ: "f",
				// 		ƒ: "f",
				// 		ꝼ: "f",
				// 		"ⓖ": "g",
				// 		ｇ: "g",
				// 		ǵ: "g",
				// 		ĝ: "g",
				// 		ḡ: "g",
				// 		ğ: "g",
				// 		ġ: "g",
				// 		ǧ: "g",
				// 		ģ: "g",
				// 		ǥ: "g",
				// 		ɠ: "g",
				// 		ꞡ: "g",
				// 		ᵹ: "g",
				// 		ꝿ: "g",
				// 		"ⓗ": "h",
				// 		ｈ: "h",
				// 		ĥ: "h",
				// 		ḣ: "h",
				// 		ḧ: "h",
				// 		ȟ: "h",
				// 		ḥ: "h",
				// 		ḩ: "h",
				// 		ḫ: "h",
				// 		ẖ: "h",
				// 		ħ: "h",
				// 		ⱨ: "h",
				// 		ⱶ: "h",
				// 		ɥ: "h",
				// 		ƕ: "hv",
				// 		"ⓘ": "i",
				// 		ｉ: "i",
				// 		ì: "i",
				// 		í: "i",
				// 		î: "i",
				// 		ĩ: "i",
				// 		ī: "i",
				// 		ĭ: "i",
				// 		ï: "i",
				// 		ḯ: "i",
				// 		ỉ: "i",
				// 		ǐ: "i",
				// 		ȉ: "i",
				// 		ȋ: "i",
				// 		ị: "i",
				// 		į: "i",
				// 		ḭ: "i",
				// 		ɨ: "i",
				// 		ı: "i",
				// 		"ⓙ": "j",
				// 		ｊ: "j",
				// 		ĵ: "j",
				// 		ǰ: "j",
				// 		ɉ: "j",
				// 		"ⓚ": "k",
				// 		ｋ: "k",
				// 		ḱ: "k",
				// 		ǩ: "k",
				// 		ḳ: "k",
				// 		ķ: "k",
				// 		ḵ: "k",
				// 		ƙ: "k",
				// 		ⱪ: "k",
				// 		ꝁ: "k",
				// 		ꝃ: "k",
				// 		ꝅ: "k",
				// 		ꞣ: "k",
				// 		"ⓛ": "l",
				// 		ｌ: "l",
				// 		ŀ: "l",
				// 		ĺ: "l",
				// 		ľ: "l",
				// 		ḷ: "l",
				// 		ḹ: "l",
				// 		ļ: "l",
				// 		ḽ: "l",
				// 		ḻ: "l",
				// 		ſ: "l",
				// 		ł: "l",
				// 		ƚ: "l",
				// 		ɫ: "l",
				// 		ⱡ: "l",
				// 		ꝉ: "l",
				// 		ꞁ: "l",
				// 		ꝇ: "l",
				// 		ǉ: "lj",
				// 		"ⓜ": "m",
				// 		ｍ: "m",
				// 		ḿ: "m",
				// 		ṁ: "m",
				// 		ṃ: "m",
				// 		ɱ: "m",
				// 		ɯ: "m",
				// 		"ⓝ": "n",
				// 		ｎ: "n",
				// 		ǹ: "n",
				// 		ń: "n",
				// 		ñ: "n",
				// 		ṅ: "n",
				// 		ň: "n",
				// 		ṇ: "n",
				// 		ņ: "n",
				// 		ṋ: "n",
				// 		ṉ: "n",
				// 		ƞ: "n",
				// 		ɲ: "n",
				// 		ŉ: "n",
				// 		ꞑ: "n",
				// 		ꞥ: "n",
				// 		ǌ: "nj",
				// 		"ⓞ": "o",
				// 		ｏ: "o",
				// 		ò: "o",
				// 		ó: "o",
				// 		ô: "o",
				// 		ồ: "o",
				// 		ố: "o",
				// 		ỗ: "o",
				// 		ổ: "o",
				// 		õ: "o",
				// 		ṍ: "o",
				// 		ȭ: "o",
				// 		ṏ: "o",
				// 		ō: "o",
				// 		ṑ: "o",
				// 		ṓ: "o",
				// 		ŏ: "o",
				// 		ȯ: "o",
				// 		ȱ: "o",
				// 		ö: "o",
				// 		ȫ: "o",
				// 		ỏ: "o",
				// 		ő: "o",
				// 		ǒ: "o",
				// 		ȍ: "o",
				// 		ȏ: "o",
				// 		ơ: "o",
				// 		ờ: "o",
				// 		ớ: "o",
				// 		ỡ: "o",
				// 		ở: "o",
				// 		ợ: "o",
				// 		ọ: "o",
				// 		ộ: "o",
				// 		ǫ: "o",
				// 		ǭ: "o",
				// 		ø: "o",
				// 		ǿ: "o",
				// 		ɔ: "o",
				// 		ꝋ: "o",
				// 		ꝍ: "o",
				// 		ɵ: "o",
				// 		œ: "oe",
				// 		ƣ: "oi",
				// 		ȣ: "ou",
				// 		ꝏ: "oo",
				// 		"ⓟ": "p",
				// 		ｐ: "p",
				// 		ṕ: "p",
				// 		ṗ: "p",
				// 		ƥ: "p",
				// 		ᵽ: "p",
				// 		ꝑ: "p",
				// 		ꝓ: "p",
				// 		ꝕ: "p",
				// 		"ⓠ": "q",
				// 		ｑ: "q",
				// 		ɋ: "q",
				// 		ꝗ: "q",
				// 		ꝙ: "q",
				// 		"ⓡ": "r",
				// 		ｒ: "r",
				// 		ŕ: "r",
				// 		ṙ: "r",
				// 		ř: "r",
				// 		ȑ: "r",
				// 		ȓ: "r",
				// 		ṛ: "r",
				// 		ṝ: "r",
				// 		ŗ: "r",
				// 		ṟ: "r",
				// 		ɍ: "r",
				// 		ɽ: "r",
				// 		ꝛ: "r",
				// 		ꞧ: "r",
				// 		ꞃ: "r",
				// 		"ⓢ": "s",
				// 		ｓ: "s",
				// 		ß: "s",
				// 		ś: "s",
				// 		ṥ: "s",
				// 		ŝ: "s",
				// 		ṡ: "s",
				// 		š: "s",
				// 		ṧ: "s",
				// 		ṣ: "s",
				// 		ṩ: "s",
				// 		ș: "s",
				// 		ş: "s",
				// 		ȿ: "s",
				// 		ꞩ: "s",
				// 		ꞅ: "s",
				// 		ẛ: "s",
				// 		"ⓣ": "t",
				// 		ｔ: "t",
				// 		ṫ: "t",
				// 		ẗ: "t",
				// 		ť: "t",
				// 		ṭ: "t",
				// 		ț: "t",
				// 		ţ: "t",
				// 		ṱ: "t",
				// 		ṯ: "t",
				// 		ŧ: "t",
				// 		ƭ: "t",
				// 		ʈ: "t",
				// 		ⱦ: "t",
				// 		ꞇ: "t",
				// 		ꜩ: "tz",
				// 		"ⓤ": "u",
				// 		ｕ: "u",
				// 		ù: "u",
				// 		ú: "u",
				// 		û: "u",
				// 		ũ: "u",
				// 		ṹ: "u",
				// 		ū: "u",
				// 		ṻ: "u",
				// 		ŭ: "u",
				// 		ü: "u",
				// 		ǜ: "u",
				// 		ǘ: "u",
				// 		ǖ: "u",
				// 		ǚ: "u",
				// 		ủ: "u",
				// 		ů: "u",
				// 		ű: "u",
				// 		ǔ: "u",
				// 		ȕ: "u",
				// 		ȗ: "u",
				// 		ư: "u",
				// 		ừ: "u",
				// 		ứ: "u",
				// 		ữ: "u",
				// 		ử: "u",
				// 		ự: "u",
				// 		ụ: "u",
				// 		ṳ: "u",
				// 		ų: "u",
				// 		ṷ: "u",
				// 		ṵ: "u",
				// 		ʉ: "u",
				// 		"ⓥ": "v",
				// 		ｖ: "v",
				// 		ṽ: "v",
				// 		ṿ: "v",
				// 		ʋ: "v",
				// 		ꝟ: "v",
				// 		ʌ: "v",
				// 		ꝡ: "vy",
				// 		"ⓦ": "w",
				// 		ｗ: "w",
				// 		ẁ: "w",
				// 		ẃ: "w",
				// 		ŵ: "w",
				// 		ẇ: "w",
				// 		ẅ: "w",
				// 		ẘ: "w",
				// 		ẉ: "w",
				// 		ⱳ: "w",
				// 		"ⓧ": "x",
				// 		ｘ: "x",
				// 		ẋ: "x",
				// 		ẍ: "x",
				// 		"ⓨ": "y",
				// 		ｙ: "y",
				// 		ỳ: "y",
				// 		ý: "y",
				// 		ŷ: "y",
				// 		ỹ: "y",
				// 		ȳ: "y",
				// 		ẏ: "y",
				// 		ÿ: "y",
				// 		ỷ: "y",
				// 		ẙ: "y",
				// 		ỵ: "y",
				// 		ƴ: "y",
				// 		ɏ: "y",
				// 		ỿ: "y",
				// 		"ⓩ": "z",
				// 		ｚ: "z",
				// 		ź: "z",
				// 		ẑ: "z",
				// 		ż: "z",
				// 		ž: "z",
				// 		ẓ: "z",
				// 		ẕ: "z",
				// 		ƶ: "z",
				// 		ȥ: "z",
				// 		ɀ: "z",
				// 		ⱬ: "z",
				// 		ꝣ: "z",
				// 		Ά: "Α",
				// 		Έ: "Ε",
				// 		Ή: "Η",
				// 		Ί: "Ι",
				// 		Ϊ: "Ι",
				// 		Ό: "Ο",
				// 		Ύ: "Υ",
				// 		Ϋ: "Υ",
				// 		Ώ: "Ω",
				// 		ά: "α",
				// 		έ: "ε",
				// 		ή: "η",
				// 		ί: "ι",
				// 		ϊ: "ι",
				// 		ΐ: "ι",
				// 		ό: "ο",
				// 		ύ: "υ",
				// 		ϋ: "υ",
				// 		ΰ: "υ",
				// 		ώ: "ω",
				// 		ς: "σ",
				// 		"’": "'"
				// 	};
				// }),
				e.define("select2/data/base", ["../utils"], function (r) {
					function n(e, t) {
						n.__super__.constructor.call(this);
					}
					return (
						r.Extend(n, r.Observable),
						(n.prototype.current = function (e) {
							throw new Error(
								"The `current` method must be defined in child classes."
							);
						}),
						(n.prototype.query = function (e, t) {
							throw new Error("The `query` method must be defined in child classes.");
						}),
						(n.prototype.bind = function (e, t) {}),
						(n.prototype.destroy = function () {}),
						(n.prototype.generateResultId = function (e, t) {
							var n = e.id + "-result-";
							return (
								(n += r.generateChars(4)),
								null != t.id
									? (n += "-" + t.id.toString())
									: (n += "-" + r.generateChars(4)),
								n
							);
						}),
						n
					);
				}),
				e.define("select2/data/select", ["./base", "../utils", "jquery"], function (
					e,
					a,
					l
				) {
					function n(e, t) {
						(this.$element = e), (this.options = t), n.__super__.constructor.call(this);
					}
					return (
						a.Extend(n, e),
						(n.prototype.current = function (e) {
							var n = [],
								r = this;
							this.$element.find(":selected").each(function () {
								var e = l(this),
									t = r.item(e);
								n.push(t);
							}),
								e(n);
						}),
						(n.prototype.select = function (i) {
							var o = this;
							if (((i.selected = !0), l(i.element).is("option")))
								return (
									(i.element.selected = !0),
									void this.$element.trigger("input").trigger("change")
								);
							if (this.$element.prop("multiple"))
								this.current(function (e) {
									var t = [];
									(i = [i]).push.apply(i, e);
									for (var n = 0; n < i.length; n++) {
										var r = i[n].id;
										-1 === l.inArray(r, t) && t.push(r);
									}
									o.$element.val(t),
										o.$element.trigger("input").trigger("change");
								});
							else {
								var e = i.id;
								this.$element.val(e),
									this.$element.trigger("input").trigger("change");
							}
						}),
						(n.prototype.unselect = function (i) {
							var o = this;
							if (this.$element.prop("multiple")) {
								if (((i.selected = !1), l(i.element).is("option")))
									return (
										(i.element.selected = !1),
										void this.$element.trigger("input").trigger("change")
									);
								this.current(function (e) {
									for (var t = [], n = 0; n < e.length; n++) {
										var r = e[n].id;
										r !== i.id && -1 === l.inArray(r, t) && t.push(r);
									}
									o.$element.val(t),
										o.$element.trigger("input").trigger("change");
								});
							}
						}),
						(n.prototype.bind = function (e, t) {
							var n = this;
							(this.container = e).on("select", function (e) {
								n.select(e.data);
							}),
								e.on("unselect", function (e) {
									n.unselect(e.data);
								});
						}),
						(n.prototype.destroy = function () {
							this.$element.find("*").each(function () {
								a.RemoveData(this);
							});
						}),
						(n.prototype.query = function (r, e) {
							var i = [],
								o = this;
							this.$element.children().each(function () {
								var e = l(this);
								if (e.is("option") || e.is("optgroup")) {
									var t = o.item(e),
										n = o.matches(r, t);
									null !== n && i.push(n);
								}
							}),
								e({ results: i });
						}),
						(n.prototype.addOptions = function (e) {
							a.appendMany(this.$element, e);
						}),
						(n.prototype.option = function (e) {
							var t;
							e.children
								? ((t = document.createElement("optgroup")).label = e.text)
								: void 0 !== (t = document.createElement("option")).textContent
								? (t.textContent = e.text)
								: (t.innerText = e.text),
								void 0 !== e.id && (t.value = e.id),
								e.disabled && (t.disabled = !0),
								e.selected && (t.selected = !0),
								e.title && (t.title = e.title);
							var n = l(t),
								r = this._normalizeItem(e);
							return (r.element = t), a.StoreData(t, "data", r), n;
						}),
						(n.prototype.item = function (e) {
							var t = {};
							if (null != (t = a.GetData(e[0], "data"))) return t;
							if (e.is("option"))
								t = {
									id: e.val(),
									text: e.text(),
									disabled: e.prop("disabled"),
									selected: e.prop("selected"),
									title: e.prop("title")
								};
							else if (e.is("optgroup")) {
								t = {
									text: e.prop("label"),
									children: [],
									title: e.prop("title")
								};
								for (
									var n = e.children("option"), r = [], i = 0;
									i < n.length;
									i++
								) {
									var o = l(n[i]),
										s = this.item(o);
									r.push(s);
								}
								t.children = r;
							}
							return (
								((t = this._normalizeItem(t)).element = e[0]),
								a.StoreData(e[0], "data", t),
								t
							);
						}),
						(n.prototype._normalizeItem = function (e) {
							e !== Object(e) && (e = { id: e, text: e });
							return (
								null != (e = l.extend({}, { text: "" }, e)).id &&
									(e.id = e.id.toString()),
								null != e.text && (e.text = e.text.toString()),
								null == e._resultId &&
									e.id &&
									null != this.container &&
									(e._resultId = this.generateResultId(this.container, e)),
								l.extend({}, { selected: !1, disabled: !1 }, e)
							);
						}),
						(n.prototype.matches = function (e, t) {
							return this.options.get("matcher")(e, t);
						}),
						n
					);
				}),
				e.define("select2/data/array", ["./select", "../utils", "jquery"], function (
					e,
					f,
					g
				) {
					function r(e, t) {
						(this._dataToConvert = t.get("data") || []),
							r.__super__.constructor.call(this, e, t);
					}
					return (
						f.Extend(r, e),
						(r.prototype.bind = function (e, t) {
							r.__super__.bind.call(this, e, t),
								this.addOptions(this.convertToOptions(this._dataToConvert));
						}),
						(r.prototype.select = function (n) {
							var e = this.$element.find("option").filter(function (e, t) {
								return t.value == n.id.toString();
							});
							0 === e.length && ((e = this.option(n)), this.addOptions(e)),
								r.__super__.select.call(this, n);
						}),
						(r.prototype.convertToOptions = function (e) {
							var t = this,
								n = this.$element.find("option"),
								r = n
									.map(function () {
										return t.item(g(this)).id;
									})
									.get(),
								i = [];
							function o(e) {
								return function () {
									return g(this).val() == e.id;
								};
							}
							for (var s = 0; s < e.length; s++) {
								var a = this._normalizeItem(e[s]);
								if (0 <= g.inArray(a.id, r)) {
									var l = n.filter(o(a)),
										c = this.item(l),
										u = g.extend(!0, {}, a, c),
										d = this.option(u);
									l.replaceWith(d);
								} else {
									var p = this.option(a);
									if (a.children) {
										var h = this.convertToOptions(a.children);
										f.appendMany(p, h);
									}
									i.push(p);
								}
							}
							return i;
						}),
						r
					);
				}),
				e.define("select2/data/ajax", ["./array", "../utils", "jquery"], function (
					e,
					t,
					o
				) {
					function n(e, t) {
						(this.ajaxOptions = this._applyDefaults(t.get("ajax"))),
							null != this.ajaxOptions.processResults &&
								(this.processResults = this.ajaxOptions.processResults),
							n.__super__.constructor.call(this, e, t);
					}
					return (
						t.Extend(n, e),
						(n.prototype._applyDefaults = function (e) {
							var t = {
								data: function (e) {
									return o.extend({}, e, { q: e.term });
								},
								transport: function (e, t, n) {
									var r = o.ajax(e);
									return r.then(t), r.fail(n), r;
								}
							};
							return o.extend({}, t, e, !0);
						}),
						(n.prototype.processResults = function (e) {
							return e;
						}),
						(n.prototype.query = function (n, r) {
							var i = this;
							null != this._request &&
								(o.isFunction(this._request.abort) && this._request.abort(),
								(this._request = null));
							var t = o.extend({ type: "GET" }, this.ajaxOptions);
							function e() {
								var e = t.transport(
									t,
									function (e) {
										var t = i.processResults(e, n);
										i.options.get("debug") &&
											window.console &&
											console.error &&
											((t && t.results && o.isArray(t.results)) ||
												console.error(
													"Select2: The AJAX results did not return an array in the `results` key of the response."
												)),
											r(t);
									},
									function () {
										("status" in e && (0 === e.status || "0" === e.status)) ||
											i.trigger("results:message", {
												message: "errorLoading"
											});
									}
								);
								i._request = e;
							}
							"function" == typeof t.url && (t.url = t.url.call(this.$element, n)),
								"function" == typeof t.data &&
									(t.data = t.data.call(this.$element, n)),
								this.ajaxOptions.delay && null != n.term
									? (this._queryTimeout &&
											window.clearTimeout(this._queryTimeout),
									  (this._queryTimeout = window.setTimeout(
											e,
											this.ajaxOptions.delay
									  )))
									: e();
						}),
						n
					);
				}),
				e.define("select2/data/tags", ["jquery"], function (u) {
					function e(e, t, n) {
						var r = n.get("tags"),
							i = n.get("createTag");
						void 0 !== i && (this.createTag = i);
						var o = n.get("insertTag");
						if (
							(void 0 !== o && (this.insertTag = o), e.call(this, t, n), u.isArray(r))
						)
							for (var s = 0; s < r.length; s++) {
								var a = r[s],
									l = this._normalizeItem(a),
									c = this.option(l);
								this.$element.append(c);
							}
					}
					return (
						(e.prototype.query = function (e, c, u) {
							var d = this;
							this._removeOldTags(),
								null != c.term && null == c.page
									? e.call(this, c, function e(t, n) {
											for (var r = t.results, i = 0; i < r.length; i++) {
												var o = r[i],
													s =
														null != o.children &&
														!e({ results: o.children }, !0);
												if (
													(o.text || "").toUpperCase() ===
														(c.term || "").toUpperCase() ||
													s
												)
													return !n && ((t.data = r), void u(t));
											}
											if (n) return !0;
											var a = d.createTag(c);
											if (null != a) {
												var l = d.option(a);
												l.attr("data-select2-tag", !0),
													d.addOptions([l]),
													d.insertTag(r, a);
											}
											(t.results = r), u(t);
									  })
									: e.call(this, c, u);
						}),
						(e.prototype.createTag = function (e, t) {
							var n = u.trim(t.term);
							return "" === n ? null : { id: n, text: n };
						}),
						(e.prototype.insertTag = function (e, t, n) {
							t.unshift(n);
						}),
						(e.prototype._removeOldTags = function (e) {
							this.$element.find("option[data-select2-tag]").each(function () {
								this.selected || u(this).remove();
							});
						}),
						e
					);
				}),
				e.define("select2/data/tokenizer", ["jquery"], function (d) {
					function e(e, t, n) {
						var r = n.get("tokenizer");
						void 0 !== r && (this.tokenizer = r), e.call(this, t, n);
					}
					return (
						(e.prototype.bind = function (e, t, n) {
							e.call(this, t, n),
								(this.$search =
									t.dropdown.$search ||
									t.selection.$search ||
									n.find(".select2-search__field"));
						}),
						(e.prototype.query = function (e, t, n) {
							var r = this;
							t.term = t.term || "";
							var i = this.tokenizer(t, this.options, function (e) {
								var t = r._normalizeItem(e);
								if (
									!r.$element.find("option").filter(function () {
										return d(this).val() === t.id;
									}).length
								) {
									var n = r.option(t);
									n.attr("data-select2-tag", !0),
										r._removeOldTags(),
										r.addOptions([n]);
								}
								!(function (e) {
									r.trigger("select", { data: e });
								})(t);
							});
							i.term !== t.term &&
								(this.$search.length &&
									(this.$search.val(i.term), this.$search.trigger("focus")),
								(t.term = i.term)),
								e.call(this, t, n);
						}),
						(e.prototype.tokenizer = function (e, t, n, r) {
							for (
								var i = n.get("tokenSeparators") || [],
									o = t.term,
									s = 0,
									a =
										this.createTag ||
										function (e) {
											return { id: e.term, text: e.term };
										};
								s < o.length;

							) {
								var l = o[s];
								if (-1 !== d.inArray(l, i)) {
									var c = o.substr(0, s),
										u = a(d.extend({}, t, { term: c }));
									null != u ? (r(u), (o = o.substr(s + 1) || ""), (s = 0)) : s++;
								} else s++;
							}
							return { term: o };
						}),
						e
					);
				}),
				e.define("select2/data/minimumInputLength", [], function () {
					function e(e, t, n) {
						(this.minimumInputLength = n.get("minimumInputLength")), e.call(this, t, n);
					}
					return (
						(e.prototype.query = function (e, t, n) {
							(t.term = t.term || ""),
								t.term.length < this.minimumInputLength
									? this.trigger("results:message", {
											message: "inputTooShort",
											args: {
												minimum: this.minimumInputLength,
												input: t.term,
												params: t
											}
									  })
									: e.call(this, t, n);
						}),
						e
					);
				}),
				e.define("select2/data/maximumInputLength", [], function () {
					function e(e, t, n) {
						(this.maximumInputLength = n.get("maximumInputLength")), e.call(this, t, n);
					}
					return (
						(e.prototype.query = function (e, t, n) {
							(t.term = t.term || ""),
								0 < this.maximumInputLength &&
								t.term.length > this.maximumInputLength
									? this.trigger("results:message", {
											message: "inputTooLong",
											args: {
												maximum: this.maximumInputLength,
												input: t.term,
												params: t
											}
									  })
									: e.call(this, t, n);
						}),
						e
					);
				}),
				e.define("select2/data/maximumSelectionLength", [], function () {
					function e(e, t, n) {
						(this.maximumSelectionLength = n.get("maximumSelectionLength")),
							e.call(this, t, n);
					}
					return (
						(e.prototype.bind = function (e, t, n) {
							var r = this;
							e.call(this, t, n),
								t.on("select", function () {
									r._checkIfMaximumSelected();
								});
						}),
						(e.prototype.query = function (e, t, n) {
							var r = this;
							this._checkIfMaximumSelected(function () {
								e.call(r, t, n);
							});
						}),
						(e.prototype._checkIfMaximumSelected = function (e, n) {
							var r = this;
							this.current(function (e) {
								var t = null != e ? e.length : 0;
								0 < r.maximumSelectionLength && t >= r.maximumSelectionLength
									? r.trigger("results:message", {
											message: "maximumSelected",
											args: { maximum: r.maximumSelectionLength }
									  })
									: n && n();
							});
						}),
						e
					);
				}),
				e.define("select2/dropdown", ["jquery", "./utils"], function (t, e) {
					function n(e, t) {
						(this.$element = e), (this.options = t), n.__super__.constructor.call(this);
					}
					return (
						e.Extend(n, e.Observable),
						(n.prototype.render = function () {
							var e = t(
								'<span class="select2-dropdown"><span class="select2-results"></span></span>'
							);
							return e.attr("dir", this.options.get("dir")), (this.$dropdown = e);
						}),
						(n.prototype.bind = function () {}),
						(n.prototype.position = function (e, t) {}),
						(n.prototype.destroy = function () {
							this.$dropdown.remove();
						}),
						n
					);
				}),
				e.define("select2/dropdown/search", ["jquery", "../utils"], function (o, e) {
					function t() {}
					return (
						(t.prototype.render = function (e) {
							var t = e.call(this),
								n = o(
									'<span class="select2-search select2-search--dropdown"><input class="select2-search__field" type="search" tabindex="-1" autocomplete="off" autocorrect="off" autocapitalize="none" spellcheck="false" role="searchbox" aria-autocomplete="list" /></span>'
								);
							return (
								(this.$searchContainer = n),
								(this.$search = n.find("input")),
								t.prepend(n),
								t
							);
						}),
						(t.prototype.bind = function (e, t, n) {
							var r = this,
								i = t.id + "-results";
							e.call(this, t, n),
								this.$search.on("keydown", function (e) {
									r.trigger("keypress", e),
										(r._keyUpPrevented = e.isDefaultPrevented());
								}),
								this.$search.on("input", function (e) {
									o(this).off("keyup");
								}),
								this.$search.on("keyup input", function (e) {
									r.handleSearch(e);
								}),
								t.on("open", function () {
									r.$search.attr("tabindex", 0),
										r.$search.attr("aria-controls", i),
										r.$search.trigger("focus"),
										window.setTimeout(function () {
											r.$search.trigger("focus");
										}, 0);
								}),
								t.on("close", function () {
									r.$search.attr("tabindex", -1),
										r.$search.removeAttr("aria-controls"),
										r.$search.removeAttr("aria-activedescendant"),
										r.$search.val(""),
										r.$search.trigger("blur");
								}),
								t.on("focus", function () {
									t.isOpen() || r.$search.trigger("focus");
								}),
								t.on("results:all", function (e) {
									(null != e.query.term && "" !== e.query.term) ||
										(r.showSearch(e)
											? r.$searchContainer.removeClass("select2-search--hide")
											: r.$searchContainer.addClass("select2-search--hide"));
								}),
								t.on("results:focus", function (e) {
									e.data._resultId
										? r.$search.attr("aria-activedescendant", e.data._resultId)
										: r.$search.removeAttr("aria-activedescendant");
								});
						}),
						(t.prototype.handleSearch = function (e) {
							if (!this._keyUpPrevented) {
								var t = this.$search.val();
								this.trigger("query", { term: t });
							}
							this._keyUpPrevented = !1;
						}),
						(t.prototype.showSearch = function (e, t) {
							return !0;
						}),
						t
					);
				}),
				e.define("select2/dropdown/hidePlaceholder", [], function () {
					function e(e, t, n, r) {
						(this.placeholder = this.normalizePlaceholder(n.get("placeholder"))),
							e.call(this, t, n, r);
					}
					return (
						(e.prototype.append = function (e, t) {
							(t.results = this.removePlaceholder(t.results)), e.call(this, t);
						}),
						(e.prototype.normalizePlaceholder = function (e, t) {
							return "string" == typeof t && (t = { id: "", text: t }), t;
						}),
						(e.prototype.removePlaceholder = function (e, t) {
							for (var n = t.slice(0), r = t.length - 1; 0 <= r; r--) {
								var i = t[r];
								this.placeholder.id === i.id && n.splice(r, 1);
							}
							return n;
						}),
						e
					);
				}),
				e.define("select2/dropdown/infiniteScroll", ["jquery"], function (n) {
					function e(e, t, n, r) {
						(this.lastParams = {}),
							e.call(this, t, n, r),
							(this.$loadingMore = this.createLoadingMore()),
							(this.loading = !1);
					}
					return (
						(e.prototype.append = function (e, t) {
							this.$loadingMore.remove(),
								(this.loading = !1),
								e.call(this, t),
								this.showLoadingMore(t) &&
									(this.$results.append(this.$loadingMore),
									this.loadMoreIfNeeded());
						}),
						(e.prototype.bind = function (e, t, n) {
							var r = this;
							e.call(this, t, n),
								t.on("query", function (e) {
									(r.lastParams = e), (r.loading = !0);
								}),
								t.on("query:append", function (e) {
									(r.lastParams = e), (r.loading = !0);
								}),
								this.$results.on("scroll", this.loadMoreIfNeeded.bind(this));
						}),
						(e.prototype.loadMoreIfNeeded = function () {
							var e = n.contains(document.documentElement, this.$loadingMore[0]);
							if (!this.loading && e) {
								var t = this.$results.offset().top + this.$results.outerHeight(!1);
								this.$loadingMore.offset().top +
									this.$loadingMore.outerHeight(!1) <=
									t + 50 && this.loadMore();
							}
						}),
						(e.prototype.loadMore = function () {
							this.loading = !0;
							var e = n.extend({}, { page: 1 }, this.lastParams);
							e.page++, this.trigger("query:append", e);
						}),
						(e.prototype.showLoadingMore = function (e, t) {
							return t.pagination && t.pagination.more;
						}),
						(e.prototype.createLoadingMore = function () {
							var e = n(
									'<li class="select2-results__option select2-results__option--load-more"role="option" aria-disabled="true"></li>'
								),
								t = this.options.get("translations").get("loadingMore");
							return e.html(t(this.lastParams)), e;
						}),
						e
					);
				}),
				e.define("select2/dropdown/attachBody", ["jquery", "../utils"], function (f, a) {
					function e(e, t, n) {
						(this.$dropdownParent = f(n.get("dropdownParent") || document.body)),
							e.call(this, t, n);
					}
					return (
						(e.prototype.bind = function (e, t, n) {
							var r = this;
							e.call(this, t, n),
								t.on("open", function () {
									r._showDropdown(),
										r._attachPositioningHandler(t),
										r._bindContainerResultHandlers(t);
								}),
								t.on("close", function () {
									r._hideDropdown(), r._detachPositioningHandler(t);
								}),
								this.$dropdownContainer.on("mousedown", function (e) {
									e.stopPropagation();
								});
						}),
						(e.prototype.destroy = function (e) {
							e.call(this), this.$dropdownContainer.remove();
						}),
						(e.prototype.position = function (e, t, n) {
							t.attr("class", n.attr("class")),
								t.removeClass("select2"),
								t.addClass("select2-container--open"),
								t.css({ position: "absolute", top: -999999 }),
								(this.$container = n);
						}),
						(e.prototype.render = function (e) {
							var t = f("<span></span>"),
								n = e.call(this);
							return t.append(n), (this.$dropdownContainer = t);
						}),
						(e.prototype._hideDropdown = function (e) {
							this.$dropdownContainer.detach();
						}),
						(e.prototype._bindContainerResultHandlers = function (e, t) {
							if (!this._containerResultsHandlersBound) {
								var n = this;
								t.on("results:all", function () {
									n._positionDropdown(), n._resizeDropdown();
								}),
									t.on("results:append", function () {
										n._positionDropdown(), n._resizeDropdown();
									}),
									t.on("results:message", function () {
										n._positionDropdown(), n._resizeDropdown();
									}),
									t.on("select", function () {
										n._positionDropdown(), n._resizeDropdown();
									}),
									t.on("unselect", function () {
										n._positionDropdown(), n._resizeDropdown();
									}),
									(this._containerResultsHandlersBound = !0);
							}
						}),
						(e.prototype._attachPositioningHandler = function (e, t) {
							var n = this,
								r = "scroll.select2." + t.id,
								i = "resize.select2." + t.id,
								o = "orientationchange.select2." + t.id,
								s = this.$container.parents().filter(a.hasScroll);
							s.each(function () {
								a.StoreData(this, "select2-scroll-position", {
									x: f(this).scrollLeft(),
									y: f(this).scrollTop()
								});
							}),
								s.on(r, function (e) {
									var t = a.GetData(this, "select2-scroll-position");
									f(this).scrollTop(t.y);
								}),
								f(window).on(r + " " + i + " " + o, function (e) {
									n._positionDropdown(), n._resizeDropdown();
								});
						}),
						(e.prototype._detachPositioningHandler = function (e, t) {
							var n = "scroll.select2." + t.id,
								r = "resize.select2." + t.id,
								i = "orientationchange.select2." + t.id;
							this.$container.parents().filter(a.hasScroll).off(n),
								f(window).off(n + " " + r + " " + i);
						}),
						(e.prototype._positionDropdown = function () {
							var e = f(window),
								t = this.$dropdown.hasClass("select2-dropdown--above"),
								n = this.$dropdown.hasClass("select2-dropdown--below"),
								r = null,
								i = this.$container.offset();
							i.bottom = i.top + this.$container.outerHeight(!1);
							var o = { height: this.$container.outerHeight(!1) };
							(o.top = i.top), (o.bottom = i.top + o.height);
							var s = this.$dropdown.outerHeight(!1),
								a = e.scrollTop(),
								l = e.scrollTop() + e.height(),
								c = a < i.top - s,
								u = l > i.bottom + s,
								d = { left: i.left, top: o.bottom },
								p = this.$dropdownParent;
							"static" === p.css("position") && (p = p.offsetParent());
							var h = { top: 0, left: 0 };
							(f.contains(document.body, p[0]) || p[0].isConnected) &&
								(h = p.offset()),
								(d.top -= h.top),
								(d.left -= h.left),
								t || n || (r = "below"),
								u || !c || t ? !c && u && t && (r = "below") : (r = "above"),
								("above" == r || (t && "below" !== r)) &&
									(d.top = o.top - h.top - s),
								null != r &&
									(this.$dropdown
										.removeClass(
											"select2-dropdown--below select2-dropdown--above"
										)
										.addClass("select2-dropdown--" + r),
									this.$container
										.removeClass(
											"select2-container--below select2-container--above"
										)
										.addClass("select2-container--" + r)),
								this.$dropdownContainer.css(d);
						}),
						(e.prototype._resizeDropdown = function () {
							var e = { width: this.$container.outerWidth(!1) + "px" };
							this.options.get("dropdownAutoWidth") &&
								((e.minWidth = e.width),
								(e.position = "relative"),
								(e.width = "auto")),
								this.$dropdown.css(e);
						}),
						(e.prototype._showDropdown = function (e) {
							this.$dropdownContainer.appendTo(this.$dropdownParent),
								this._positionDropdown(),
								this._resizeDropdown();
						}),
						e
					);
				}),
				e.define("select2/dropdown/minimumResultsForSearch", [], function () {
					function e(e, t, n, r) {
						(this.minimumResultsForSearch = n.get("minimumResultsForSearch")),
							this.minimumResultsForSearch < 0 &&
								(this.minimumResultsForSearch = 1 / 0),
							e.call(this, t, n, r);
					}
					return (
						(e.prototype.showSearch = function (e, t) {
							return (
								!(
									(function e(t) {
										for (var n = 0, r = 0; r < t.length; r++) {
											var i = t[r];
											i.children ? (n += e(i.children)) : n++;
										}
										return n;
									})(t.data.results) < this.minimumResultsForSearch
								) && e.call(this, t)
							);
						}),
						e
					);
				}),
				e.define("select2/dropdown/selectOnClose", ["../utils"], function (o) {
					function e() {}
					return (
						(e.prototype.bind = function (e, t, n) {
							var r = this;
							e.call(this, t, n),
								t.on("close", function (e) {
									r._handleSelectOnClose(e);
								});
						}),
						(e.prototype._handleSelectOnClose = function (e, t) {
							if (t && null != t.originalSelect2Event) {
								var n = t.originalSelect2Event;
								if ("select" === n._type || "unselect" === n._type) return;
							}
							var r = this.getHighlightedResults();
							if (!(r.length < 1)) {
								var i = o.GetData(r[0], "data");
								(null != i.element && i.element.selected) ||
									(null == i.element && i.selected) ||
									this.trigger("select", { data: i });
							}
						}),
						e
					);
				}),
				e.define("select2/dropdown/closeOnSelect", [], function () {
					function e() {}
					return (
						(e.prototype.bind = function (e, t, n) {
							var r = this;
							e.call(this, t, n),
								t.on("select", function (e) {
									r._selectTriggered(e);
								}),
								t.on("unselect", function (e) {
									r._selectTriggered(e);
								});
						}),
						(e.prototype._selectTriggered = function (e, t) {
							var n = t.originalEvent;
							(n && (n.ctrlKey || n.metaKey)) ||
								this.trigger("close", {
									originalEvent: n,
									originalSelect2Event: t
								});
						}),
						e
					);
				}),
				e.define("select2/i18n/en", [], function () {
					return {
						errorLoading: function () {
							return "The results could not be loaded.";
						},
						inputTooLong: function (e) {
							var t = e.input.length - e.maximum,
								n = "Please delete " + t + " character";
							return 1 != t && (n += "s"), n;
						},
						inputTooShort: function (e) {
							return (
								"Please enter " +
								(e.minimum - e.input.length) +
								" or more characters"
							);
						},
						loadingMore: function () {
							return "Loading more results…";
						},
						maximumSelected: function (e) {
							var t = "You can only select " + e.maximum + " item";
							return 1 != e.maximum && (t += "s"), t;
						},
						noResults: function () {
							return "No results found";
						},
						searching: function () {
							return "Searching…";
						},
						removeAllItems: function () {
							return "Remove all items";
						}
					};
				}),
				e.define(
					"select2/defaults",
					[
						"jquery",
						"require",
						"./results",
						"./selection/single",
						"./selection/multiple",
						"./selection/placeholder",
						"./selection/allowClear",
						"./selection/search",
						"./selection/eventRelay",
						"./utils",
						"./translation",
						// "./diacritics",
						"./data/select",
						"./data/array",
						"./data/ajax",
						"./data/tags",
						"./data/tokenizer",
						"./data/minimumInputLength",
						"./data/maximumInputLength",
						"./data/maximumSelectionLength",
						"./dropdown",
						"./dropdown/search",
						"./dropdown/hidePlaceholder",
						"./dropdown/infiniteScroll",
						"./dropdown/attachBody",
						"./dropdown/minimumResultsForSearch",
						"./dropdown/selectOnClose",
						"./dropdown/closeOnSelect",
						"./i18n/en"
					],
					function (
						c,
						u,
						d,
						p,
						h,
						f,
						g,
						m,
						v,
						y,
						s,
						// t,
						_,
						$,
						b,
						w,
						A,
						x,
						D,
						S,
						E,
						C,
						O,
						T,
						q,
						L,
						I,
						j,
						e
					) {
						function n() {
							this.reset();
						}
						return (
							(n.prototype.apply = function (e) {
								if (null == (e = c.extend(!0, {}, this.defaults, e)).dataAdapter) {
									if (
										(null != e.ajax
											? (e.dataAdapter = b)
											: null != e.data
											? (e.dataAdapter = $)
											: (e.dataAdapter = _),
										0 < e.minimumInputLength &&
											(e.dataAdapter = y.Decorate(e.dataAdapter, x)),
										0 < e.maximumInputLength &&
											(e.dataAdapter = y.Decorate(e.dataAdapter, D)),
										0 < e.maximumSelectionLength &&
											(e.dataAdapter = y.Decorate(e.dataAdapter, S)),
										e.tags && (e.dataAdapter = y.Decorate(e.dataAdapter, w)),
										(null == e.tokenSeparators && null == e.tokenizer) ||
											(e.dataAdapter = y.Decorate(e.dataAdapter, A)),
										null != e.query)
									) {
										var t = u(e.amdBase + "compat/query");
										e.dataAdapter = y.Decorate(e.dataAdapter, t);
									}
									if (null != e.initSelection) {
										var n = u(e.amdBase + "compat/initSelection");
										e.dataAdapter = y.Decorate(e.dataAdapter, n);
									}
								}
								if (
									(null == e.resultsAdapter &&
										((e.resultsAdapter = d),
										null != e.ajax &&
											(e.resultsAdapter = y.Decorate(e.resultsAdapter, T)),
										null != e.placeholder &&
											(e.resultsAdapter = y.Decorate(e.resultsAdapter, O)),
										e.selectOnClose &&
											(e.resultsAdapter = y.Decorate(e.resultsAdapter, I))),
									null == e.dropdownAdapter)
								) {
									if (e.multiple) e.dropdownAdapter = E;
									else {
										var r = y.Decorate(E, C);
										e.dropdownAdapter = r;
									}
									if (
										(0 !== e.minimumResultsForSearch &&
											(e.dropdownAdapter = y.Decorate(e.dropdownAdapter, L)),
										e.closeOnSelect &&
											(e.dropdownAdapter = y.Decorate(e.dropdownAdapter, j)),
										null != e.dropdownCssClass ||
											null != e.dropdownCss ||
											null != e.adaptDropdownCssClass)
									) {
										var i = u(e.amdBase + "compat/dropdownCss");
										e.dropdownAdapter = y.Decorate(e.dropdownAdapter, i);
									}
									e.dropdownAdapter = y.Decorate(e.dropdownAdapter, q);
								}
								if (null == e.selectionAdapter) {
									if (
										(e.multiple
											? (e.selectionAdapter = h)
											: (e.selectionAdapter = p),
										null != e.placeholder &&
											(e.selectionAdapter = y.Decorate(
												e.selectionAdapter,
												f
											)),
										e.allowClear &&
											(e.selectionAdapter = y.Decorate(
												e.selectionAdapter,
												g
											)),
										e.multiple &&
											(e.selectionAdapter = y.Decorate(
												e.selectionAdapter,
												m
											)),
										null != e.containerCssClass ||
											null != e.containerCss ||
											null != e.adaptContainerCssClass)
									) {
										var o = u(e.amdBase + "compat/containerCss");
										e.selectionAdapter = y.Decorate(e.selectionAdapter, o);
									}
									e.selectionAdapter = y.Decorate(e.selectionAdapter, v);
								}
								(e.language = this._resolveLanguage(e.language)),
									e.language.push("en");
								for (var s = [], a = 0; a < e.language.length; a++) {
									var l = e.language[a];
									-1 === s.indexOf(l) && s.push(l);
								}
								return (
									(e.language = s),
									(e.translations = this._processTranslations(
										e.language,
										e.debug
									)),
									e
								);
							}),
							(n.prototype.reset = function () {
								function a(e) {
									return e.replace(/[^\u0000-\u007E]/g, function (e) {
										return t[e] || e;
									});
								}
								this.defaults = {
									amdBase: "./",
									amdLanguageBase: "./i18n/",
									closeOnSelect: !0,
									debug: !1,
									dropdownAutoWidth: !1,
									escapeMarkup: y.escapeMarkup,
									language: {},
									matcher: function e(t, n) {
										if ("" === c.trim(t.term)) return n;
										if (n.children && 0 < n.children.length) {
											for (
												var r = c.extend(!0, {}, n),
													i = n.children.length - 1;
												0 <= i;
												i--
											)
												null == e(t, n.children[i]) &&
													r.children.splice(i, 1);
											return 0 < r.children.length ? r : e(t, r);
										}
										var o = a(n.text).toUpperCase(),
											s = a(t.term).toUpperCase();
										return -1 < o.indexOf(s) ? n : null;
									},
									minimumInputLength: 0,
									maximumInputLength: 0,
									maximumSelectionLength: 0,
									minimumResultsForSearch: 0,
									selectOnClose: !1,
									scrollAfterSelect: !1,
									sorter: function (e) {
										return e;
									},
									templateResult: function (e) {
										return e.text;
									},
									templateSelection: function (e) {
										return e.text;
									},
									theme: "default",
									width: "resolve"
								};
							}),
							(n.prototype.applyFromElement = function (e, t) {
								var n = e.language,
									r = this.defaults.language,
									i = t.prop("lang"),
									o = t.closest("[lang]").prop("lang"),
									s = Array.prototype.concat.call(
										this._resolveLanguage(i),
										this._resolveLanguage(n),
										this._resolveLanguage(r),
										this._resolveLanguage(o)
									);
								return (e.language = s), e;
							}),
							(n.prototype._resolveLanguage = function (e) {
								if (!e) return [];
								if (c.isEmptyObject(e)) return [];
								if (c.isPlainObject(e)) return [e];
								var t;
								t = c.isArray(e) ? e : [e];
								for (var n = [], r = 0; r < t.length; r++)
									if (
										(n.push(t[r]),
										"string" == typeof t[r] && 0 < t[r].indexOf("-"))
									) {
										var i = t[r].split("-")[0];
										n.push(i);
									}
								return n;
							}),
							(n.prototype._processTranslations = function (e, t) {
								for (var n = new s(), r = 0; r < e.length; r++) {
									var i = new s(),
										o = e[r];
									if ("string" == typeof o)
										try {
											i = s.loadPath(o);
										} catch (e) {
											try {
												(o = this.defaults.amdLanguageBase + o),
													(i = s.loadPath(o));
											} catch (e) {
												t &&
													window.console &&
													console.warn &&
													console.warn(
														'Select2: The language file for "' +
															o +
															'" could not be automatically loaded. A fallback will be used instead.'
													);
											}
										}
									else i = c.isPlainObject(o) ? new s(o) : o;
									n.extend(i);
								}
								return n;
							}),
							(n.prototype.set = function (e, t) {
								var n = {};
								n[c.camelCase(e)] = t;
								var r = y._convertData(n);
								c.extend(!0, this.defaults, r);
							}),
							new n()
						);
					}
				),
				e.define(
					"select2/options",
					["require", "jquery", "./defaults", "./utils"],
					function (r, d, i, p) {
						function e(e, t) {
							if (
								((this.options = e),
								null != t && this.fromElement(t),
								null != t && (this.options = i.applyFromElement(this.options, t)),
								(this.options = i.apply(this.options)),
								t && t.is("input"))
							) {
								var n = r(this.get("amdBase") + "compat/inputData");
								this.options.dataAdapter = p.Decorate(this.options.dataAdapter, n);
							}
						}
						return (
							(e.prototype.fromElement = function (e) {
								var t = ["select2"];
								null == this.options.multiple &&
									(this.options.multiple = e.prop("multiple")),
									null == this.options.disabled &&
										(this.options.disabled = e.prop("disabled")),
									null == this.options.dir &&
										(e.prop("dir")
											? (this.options.dir = e.prop("dir"))
											: e.closest("[dir]").prop("dir")
											? (this.options.dir = e.closest("[dir]").prop("dir"))
											: (this.options.dir = "ltr")),
									e.prop("disabled", this.options.disabled),
									e.prop("multiple", this.options.multiple),
									p.GetData(e[0], "select2Tags") &&
										(this.options.debug &&
											window.console &&
											console.warn &&
											console.warn(
												'Select2: The `data-select2-tags` attribute has been changed to use the `data-data` and `data-tags="true"` attributes and will be removed in future versions of Select2.'
											),
										p.StoreData(e[0], "data", p.GetData(e[0], "select2Tags")),
										p.StoreData(e[0], "tags", !0)),
									p.GetData(e[0], "ajaxUrl") &&
										(this.options.debug &&
											window.console &&
											console.warn &&
											console.warn(
												"Select2: The `data-ajax-url` attribute has been changed to `data-ajax--url` and support for the old attribute will be removed in future versions of Select2."
											),
										e.attr("ajax--url", p.GetData(e[0], "ajaxUrl")),
										p.StoreData(e[0], "ajax-Url", p.GetData(e[0], "ajaxUrl")));
								var n = {};
								function r(e, t) {
									return t.toUpperCase();
								}
								for (var i = 0; i < e[0].attributes.length; i++) {
									var o = e[0].attributes[i].name,
										s = "data-";
									if (o.substr(0, s.length) == s) {
										var a = o.substring(s.length),
											l = p.GetData(e[0], a);
										n[a.replace(/-([a-z])/g, r)] = l;
									}
								}
								d.fn.jquery &&
									"1." == d.fn.jquery.substr(0, 2) &&
									e[0].dataset &&
									(n = d.extend(!0, {}, e[0].dataset, n));
								var c = d.extend(!0, {}, p.GetData(e[0]), n);
								for (var u in (c = p._convertData(c)))
									-1 < d.inArray(u, t) ||
										(d.isPlainObject(this.options[u])
											? d.extend(this.options[u], c[u])
											: (this.options[u] = c[u]));
								return this;
							}),
							(e.prototype.get = function (e) {
								return this.options[e];
							}),
							(e.prototype.set = function (e, t) {
								this.options[e] = t;
							}),
							e
						);
					}
				),
				e.define("select2/core", ["jquery", "./options", "./utils", "./keys"], function (
					o,
					c,
					u,
					r
				) {
					var d = function (e, t) {
						null != u.GetData(e[0], "select2") && u.GetData(e[0], "select2").destroy(),
							(this.$element = e),
							(this.id = this._generateId(e)),
							(t = t || {}),
							(this.options = new c(t, e)),
							d.__super__.constructor.call(this);
						var n = e.attr("tabindex") || 0;
						u.StoreData(e[0], "old-tabindex", n), e.attr("tabindex", "-1");
						var r = this.options.get("dataAdapter");
						this.dataAdapter = new r(e, this.options);
						var i = this.render();
						this._placeContainer(i);
						var o = this.options.get("selectionAdapter");
						(this.selection = new o(e, this.options)),
							(this.$selection = this.selection.render()),
							this.selection.position(this.$selection, i);
						var s = this.options.get("dropdownAdapter");
						(this.dropdown = new s(e, this.options)),
							(this.$dropdown = this.dropdown.render()),
							this.dropdown.position(this.$dropdown, i);
						var a = this.options.get("resultsAdapter");
						(this.results = new a(e, this.options, this.dataAdapter)),
							(this.$results = this.results.render()),
							this.results.position(this.$results, this.$dropdown);
						var l = this;
						this._bindAdapters(),
							this._registerDomEvents(),
							this._registerDataEvents(),
							this._registerSelectionEvents(),
							this._registerDropdownEvents(),
							this._registerResultsEvents(),
							this._registerEvents(),
							this.dataAdapter.current(function (e) {
								l.trigger("selection:update", { data: e });
							}),
							e.addClass("select2-hidden-accessible"),
							e.attr("aria-hidden", "true"),
							this._syncAttributes(),
							u.StoreData(e[0], "select2", this),
							e.data("select2", this);
					};
					return (
						u.Extend(d, u.Observable),
						(d.prototype._generateId = function (e) {
							return (
								"select2-" +
								(null != e.attr("id")
									? e.attr("id")
									: null != e.attr("name")
									? e.attr("name") + "-" + u.generateChars(2)
									: u.generateChars(4)
								).replace(/(:|\.|\[|\]|,)/g, "")
							);
						}),
						(d.prototype._placeContainer = function (e) {
							e.insertAfter(this.$element);
							var t = this._resolveWidth(this.$element, this.options.get("width"));
							null != t && e.css("width", t);
						}),
						(d.prototype._resolveWidth = function (e, t) {
							var n = /^width:(([-+]?([0-9]*\.)?[0-9]+)(px|em|ex|%|in|cm|mm|pt|pc))/i;
							if ("resolve" == t) {
								var r = this._resolveWidth(e, "style");
								return null != r ? r : this._resolveWidth(e, "element");
							}
							if ("element" == t) {
								var i = e.outerWidth(!1);
								return i <= 0 ? "auto" : i + "px";
							}
							if ("style" != t)
								return "computedstyle" != t
									? t
									: window.getComputedStyle(e[0]).width;
							var o = e.attr("style");
							if ("string" != typeof o) return null;
							for (var s = o.split(";"), a = 0, l = s.length; a < l; a += 1) {
								var c = s[a].replace(/\s/g, "").match(n);
								if (null !== c && 1 <= c.length) return c[1];
							}
							return null;
						}),
						(d.prototype._bindAdapters = function () {
							this.dataAdapter.bind(this, this.$container),
								this.selection.bind(this, this.$container),
								this.dropdown.bind(this, this.$container),
								this.results.bind(this, this.$container);
						}),
						(d.prototype._registerDomEvents = function () {
							var t = this;
							this.$element.on("change.select2", function () {
								t.dataAdapter.current(function (e) {
									t.trigger("selection:update", { data: e });
								});
							}),
								this.$element.on("focus.select2", function (e) {
									t.trigger("focus", e);
								}),
								(this._syncA = u.bind(this._syncAttributes, this)),
								(this._syncS = u.bind(this._syncSubtree, this)),
								this.$element[0].attachEvent &&
									this.$element[0].attachEvent("onpropertychange", this._syncA);
							var e =
								window.MutationObserver ||
								window.WebKitMutationObserver ||
								window.MozMutationObserver;
							null != e
								? ((this._observer = new e(function (e) {
										t._syncA(), t._syncS(null, e);
								  })),
								  this._observer.observe(this.$element[0], {
										attributes: !0,
										childList: !0,
										subtree: !1
								  }))
								: this.$element[0].addEventListener &&
								  (this.$element[0].addEventListener(
										"DOMAttrModified",
										t._syncA,
										!1
								  ),
								  this.$element[0].addEventListener(
										"DOMNodeInserted",
										t._syncS,
										!1
								  ),
								  this.$element[0].addEventListener(
										"DOMNodeRemoved",
										t._syncS,
										!1
								  ));
						}),
						(d.prototype._registerDataEvents = function () {
							var n = this;
							this.dataAdapter.on("*", function (e, t) {
								n.trigger(e, t);
							});
						}),
						(d.prototype._registerSelectionEvents = function () {
							var n = this,
								r = ["toggle", "focus"];
							this.selection.on("toggle", function () {
								n.toggleDropdown();
							}),
								this.selection.on("focus", function (e) {
									n.focus(e);
								}),
								this.selection.on("*", function (e, t) {
									-1 === o.inArray(e, r) && n.trigger(e, t);
								});
						}),
						(d.prototype._registerDropdownEvents = function () {
							var n = this;
							this.dropdown.on("*", function (e, t) {
								n.trigger(e, t);
							});
						}),
						(d.prototype._registerResultsEvents = function () {
							var n = this;
							this.results.on("*", function (e, t) {
								n.trigger(e, t);
							});
						}),
						(d.prototype._registerEvents = function () {
							var n = this;
							this.on("open", function () {
								n.$container.addClass("select2-container--open");
							}),
								this.on("close", function () {
									n.$container.removeClass("select2-container--open");
								}),
								this.on("enable", function () {
									n.$container.removeClass("select2-container--disabled");
								}),
								this.on("disable", function () {
									n.$container.addClass("select2-container--disabled");
								}),
								this.on("blur", function () {
									n.$container.removeClass("select2-container--focus");
								}),
								this.on("query", function (t) {
									n.isOpen() || n.trigger("open", {}),
										this.dataAdapter.query(t, function (e) {
											n.trigger("results:all", { data: e, query: t });
										});
								}),
								this.on("query:append", function (t) {
									this.dataAdapter.query(t, function (e) {
										n.trigger("results:append", { data: e, query: t });
									});
								}),
								this.on("keypress", function (e) {
									var t = e.which;
									n.isOpen()
										? t === r.ESC || t === r.TAB || (t === r.UP && e.altKey)
											? (n.close(e), e.preventDefault())
											: t === r.ENTER
											? (n.trigger("results:select", {}), e.preventDefault())
											: t === r.SPACE && e.ctrlKey
											? (n.trigger("results:toggle", {}), e.preventDefault())
											: t === r.UP
											? (n.trigger("results:previous", {}),
											  e.preventDefault())
											: t === r.DOWN &&
											  (n.trigger("results:next", {}), e.preventDefault())
										: (t === r.ENTER ||
												t === r.SPACE ||
												(t === r.DOWN && e.altKey)) &&
										  (n.open(), e.preventDefault());
								});
						}),
						(d.prototype._syncAttributes = function () {
							this.options.set("disabled", this.$element.prop("disabled")),
								this.isDisabled()
									? (this.isOpen() && this.close(), this.trigger("disable", {}))
									: this.trigger("enable", {});
						}),
						(d.prototype._isChangeMutation = function (e, t) {
							var n = !1,
								r = this;
							if (
								!e ||
								!e.target ||
								"OPTION" === e.target.nodeName ||
								"OPTGROUP" === e.target.nodeName
							) {
								if (t)
									if (t.addedNodes && 0 < t.addedNodes.length)
										for (var i = 0; i < t.addedNodes.length; i++) {
											t.addedNodes[i].selected && (n = !0);
										}
									else
										t.removedNodes && 0 < t.removedNodes.length
											? (n = !0)
											: o.isArray(t) &&
											  o.each(t, function (e, t) {
													if (r._isChangeMutation(e, t)) return !(n = !0);
											  });
								else n = !0;
								return n;
							}
						}),
						(d.prototype._syncSubtree = function (e, t) {
							var n = this._isChangeMutation(e, t),
								r = this;
							n &&
								this.dataAdapter.current(function (e) {
									r.trigger("selection:update", { data: e });
								});
						}),
						(d.prototype.trigger = function (e, t) {
							var n = d.__super__.trigger,
								r = {
									open: "opening",
									close: "closing",
									select: "selecting",
									unselect: "unselecting",
									clear: "clearing"
								};
							if ((void 0 === t && (t = {}), e in r)) {
								var i = r[e],
									o = { prevented: !1, name: e, args: t };
								if ((n.call(this, i, o), o.prevented))
									return void (t.prevented = !0);
							}
							n.call(this, e, t);
						}),
						(d.prototype.toggleDropdown = function () {
							this.isDisabled() || (this.isOpen() ? this.close() : this.open());
						}),
						(d.prototype.open = function () {
							this.isOpen() || this.isDisabled() || this.trigger("query", {});
						}),
						(d.prototype.close = function (e) {
							this.isOpen() && this.trigger("close", { originalEvent: e });
						}),
						(d.prototype.isEnabled = function () {
							return !this.isDisabled();
						}),
						(d.prototype.isDisabled = function () {
							return this.options.get("disabled");
						}),
						(d.prototype.isOpen = function () {
							return this.$container.hasClass("select2-container--open");
						}),
						(d.prototype.hasFocus = function () {
							return this.$container.hasClass("select2-container--focus");
						}),
						(d.prototype.focus = function (e) {
							this.hasFocus() ||
								(this.$container.addClass("select2-container--focus"),
								this.trigger("focus", {}));
						}),
						(d.prototype.enable = function (e) {
							this.options.get("debug") &&
								window.console &&
								console.warn &&
								console.warn(
									'Select2: The `select2("enable")` method has been deprecated and will be removed in later Select2 versions. Use $element.prop("disabled") instead.'
								),
								(null != e && 0 !== e.length) || (e = [!0]);
							var t = !e[0];
							this.$element.prop("disabled", t);
						}),
						(d.prototype.data = function () {
							this.options.get("debug") &&
								0 < arguments.length &&
								window.console &&
								console.warn &&
								console.warn(
									'Select2: Data can no longer be set using `select2("data")`. You should consider setting the value instead using `$element.val()`.'
								);
							var t = [];
							return (
								this.dataAdapter.current(function (e) {
									t = e;
								}),
								t
							);
						}),
						(d.prototype.val = function (e) {
							if (
								(this.options.get("debug") &&
									window.console &&
									console.warn &&
									console.warn(
										'Select2: The `select2("val")` method has been deprecated and will be removed in later Select2 versions. Use $element.val() instead.'
									),
								null == e || 0 === e.length)
							)
								return this.$element.val();
							var t = e[0];
							o.isArray(t) &&
								(t = o.map(t, function (e) {
									return e.toString();
								})),
								this.$element.val(t).trigger("input").trigger("change");
						}),
						(d.prototype.destroy = function () {
							this.$container.remove(),
								this.$element[0].detachEvent &&
									this.$element[0].detachEvent("onpropertychange", this._syncA),
								null != this._observer
									? (this._observer.disconnect(), (this._observer = null))
									: this.$element[0].removeEventListener &&
									  (this.$element[0].removeEventListener(
											"DOMAttrModified",
											this._syncA,
											!1
									  ),
									  this.$element[0].removeEventListener(
											"DOMNodeInserted",
											this._syncS,
											!1
									  ),
									  this.$element[0].removeEventListener(
											"DOMNodeRemoved",
											this._syncS,
											!1
									  )),
								(this._syncA = null),
								(this._syncS = null),
								this.$element.off(".select2"),
								this.$element.attr(
									"tabindex",
									u.GetData(this.$element[0], "old-tabindex")
								),
								this.$element.removeClass("select2-hidden-accessible"),
								this.$element.attr("aria-hidden", "false"),
								u.RemoveData(this.$element[0]),
								this.$element.removeData("select2"),
								this.dataAdapter.destroy(),
								this.selection.destroy(),
								this.dropdown.destroy(),
								this.results.destroy(),
								(this.dataAdapter = null),
								(this.selection = null),
								(this.dropdown = null),
								(this.results = null);
						}),
						(d.prototype.render = function () {
							var e = o(
								'<span class="select2 select2-container"><span class="selection"></span><span class="dropdown-wrapper" aria-hidden="true"></span></span>'
							);
							return (
								e.attr("dir", this.options.get("dir")),
								(this.$container = e),
								this.$container.addClass(
									"select2-container--" + this.options.get("theme")
								),
								u.StoreData(e[0], "element", this.$element),
								e
							);
						}),
						d
					);
				}),
				e.define("jquery-mousewheel", ["jquery"], function (e) {
					return e;
				}),
				e.define(
					"jquery.select2",
					[
						"jquery",
						"jquery-mousewheel",
						"./select2/core",
						"./select2/defaults",
						"./select2/utils"
					],
					function (i, e, o, t, s) {
						if (null == i.fn.select2) {
							var a = ["open", "close", "destroy"];
							i.fn.select2 = function (t) {
								if ("object" == typeof (t = t || {}))
									return (
										this.each(function () {
											var e = i.extend(!0, {}, t);
											new o(i(this), e);
										}),
										this
									);
								if ("string" != typeof t)
									throw new Error("Invalid arguments for Select2: " + t);
								var n,
									r = Array.prototype.slice.call(arguments, 1);
								return (
									this.each(function () {
										var e = s.GetData(this, "select2");
										null == e &&
											window.console &&
											console.error &&
											console.error(
												"The select2('" +
													t +
													"') method was called on an element that is not using Select2."
											),
											(n = e[t].apply(e, r));
									}),
									-1 < i.inArray(t, a) ? this : n
								);
							};
						}
						return null == i.fn.select2.defaults && (i.fn.select2.defaults = t), o;
					}
				),
				{ define: e.define, require: e.require }
			);
		})(),
		t = e.require("jquery.select2");
	return (u.fn.select2.amd = e), t;
});
