/**
 * This jQuery Function is used to shuffle around the testimonial li
 * items once they are rendered to the DOM for the Customers Pre-Login Page
 */
(function($) {
	$.fn.shuffle = function() {

		let allElems = this.get(),
			getRandom = function (max) {
				return Math.floor(Math.random() * max);
			},
			shuffled = $.map(allElems, function () {
				let random = getRandom(allElems.length),
					randEl = $(allElems[random]).clone(true)[0];
				allElems.splice(random, 1);
				return randEl;
			});

		this.each(function (i) {
			$(this).replaceWith($(shuffled[i]));
		});

		return $(shuffled);

	};

	// Call the custom shuffle function once the page loads
	$(document).ready(function() {
		$(".testimonials li").shuffle();
	});

}(jQuery));