jQuery(document).ready(function($) {
	//on mobile - open/close primary navigation clicking/tapping the menu icon
	$(".cd-primary-nav").on("click", function(event) {
		if ($(event.target).is(".cd-primary-nav"))
			$(this)
				.children("ul")
				.toggleClass("is-visible");
	});

	//change visible slide
	$(".cd-slider-nav li").on("click", function(event) {
		activateSlide($(this), event, true);
	});

	function activateSlide($item, event, isStop) {
		stopTimer();
		if (event) {
			event.preventDefault();
		}

		var selectedItem = $item;
		if (!selectedItem.hasClass("selected")) {
			// if it's not already selected
			var selectedPosition = selectedItem.index(),
				activePosition = $(".cd-hero-slider .selected").index();
			if (activePosition < selectedPosition) {
				nextSlide($(".cd-hero-slider"), $(".cd-slider-nav"), selectedPosition);
			} else {
				prevSlide($(".cd-hero-slider"), $(".cd-slider-nav"), selectedPosition);
			}

			updateNavigationMarker(selectedPosition + 1);
		}
		startTimer();
	}

	$(".cd-hero-slider")
		.on("mouseenter", function() {
			stopTimer();
			// console.warn("slide entered, timer stopped");
		})
		.on("mouseleave", function() {
			// console.warn("slide left, timer started");
			startTimer();
		});

	function nextSlide(container, pagination, n) {
		var visibleSlide = container.find(".selected"),
			navigationDot = pagination.find(".selected");

		visibleSlide
			.removeClass("selected from-left from-right")
			.addClass("is-moving")
			.one(
				"webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend",
				function() {
					visibleSlide.removeClass("is-moving");
				}
			);

		container
			.children("li")
			.eq(n)
			.addClass("selected from-right")
			.prevAll()
			.addClass("move-left");
		navigationDot.removeClass("selected");
		pagination
			.find("li")
			.eq(n)
			.addClass("selected");
	}

	window.nextSlide = nextSlide;

	function prevSlide(container, pagination, n) {
		var visibleSlide = container.find(".selected"),
			navigationDot = pagination.find(".selected");

		visibleSlide
			.removeClass("selected from-left from-right")
			.addClass("is-moving")
			.one(
				"webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend",
				function() {
					visibleSlide.removeClass("is-moving");
				}
			);

		container
			.children("li")
			.eq(n)
			.addClass("selected from-left")
			.removeClass("move-left")
			.nextAll()
			.removeClass("move-left");
		navigationDot.removeClass("selected");
		pagination
			.find("li")
			.eq(n)
			.addClass("selected");
	}

	function updateNavigationMarker(n) {
		$(".cd-marker")
			.removeClassPrefix("item")
			.addClass("item-" + n);
	}

	$.fn.removeClassPrefix = function(prefix) {
		//remove all classes starting with 'prefix'
		this.each(function(i, el) {
			var classes = el.className.split(" ").filter(function(c) {
				return c.lastIndexOf(prefix, 0) !== 0;
			});
			el.className = $.trim(classes.join(" "));
		});
		return this;
	};

	// Start the slider autoplay
	var timer;

	function startTimer() {
		timer = setInterval(function() {
			var selectedItem = $(".cd-slider-nav li.selected"),
				selectedPosition = selectedItem.index();

			var stop = selectedPosition === $(".cd-slider-nav li").length - 1;

			if (!stop) {
				selectedPosition++;
				nextSlide($(".cd-hero-slider"), $(".cd-slider-nav"), selectedPosition);
				updateNavigationMarker(selectedPosition + 1);
			} else {
				activateSlide($(".cd-slider-nav li:first"), null, true);
			}
		}, 4500);
	}

	startTimer();

	function stopTimer() {
		clearInterval(timer);
	}
});
