import $ from "jquery";

$(document).ready(function () {
	/**
	 * Locating and storing the form correlating to the login button.
	 */
	var form = $("#loginButton").closest("form");

	$('[data-toggle="tooltip"]').tooltip();

	/**
	 * Disabling the submit button when the form has been submitted.
	 * Fixes a login bug which errors out when more than one submission is made.
	 */
	form.submit(function () {
		$("#loginButton").prop("disabled", true);
	});

	if (!$("#UserId").val()) {
		$("#UserId").focus();
	} else {
		$("#Password").focus();
	}

	// Disable login for IE8 and below
	$(".no-opacity input").prop("disabled", true);
});

// Set a logout time for message passing
if (
	window.location.pathname &&
	window.location.pathname.toLowerCase() === "/authentication/login"
) {
	localStorage.setItem("message", JSON.stringify({ logoutTime: +new Date() }));

	// #region Remove additional local storage items

	// INFO: Keys can be found in the constants.js file
	// NOTE: The following local storage item keys match those specified in the `NGXGRIDLSSTATEKEYS` constant
	// Ngx FAIR List LS Items
	localStorage.removeItem("ni-ngx-fairInternalList-state");
	localStorage.removeItem("ni-ngx-fairSupplierList-state");
	localStorage.removeItem("ni-ngx-fairListReturnTo");
	localStorage.removeItem("ni-ngx-lastFairListUrlVisited");

	// Ngx APQP List LS Items
	localStorage.removeItem("ni-ngx-apqpInternalList-state");
	localStorage.removeItem("ni-ngx-apqpSupplierList-state");
	localStorage.removeItem("ni-ngx-apqpInternalElementsList-state");
	localStorage.removeItem("ni-ngx-apqpSupplierElementsList-state");

	// Ngx Audit List LS Items
	localStorage.removeItem("ni-ngx-auditInternalList-state");
	localStorage.removeItem("ni-ngx-auditSupplierList-state");
	localStorage.removeItem("ni-ngx-auditCustomerList-state");

	// Ngx CARS List LS items
	localStorage.removeItem("ni-ngx-carInternalList-state");
	localStorage.removeItem("ni-ngx-carSupplierList-state");
	localStorage.removeItem("ni-ngx-carCustomerList-state");

	// Ngx AIRFOIL List LS Items
	localStorage.removeItem("ni-ngx-airfoilPartList-state");
	localStorage.removeItem("ni-ngx-airfoilPartSerialEntitys-state");
	localStorage.removeItem("ni-ngx-airfoilPartSerialNumberList-state");

	// Ngx Audit Checklist Section LS Items
	localStorage.removeItem("ni-ngx-auditChecklistSectionStatus");

	// Ngx CALIBRATION LS Items
	localStorage.removeItem("ni-ngx-toolsList-state");
	localStorage.removeItem("ni-ngx-dueAndOverdueToolsList-state");
	localStorage.removeItem("ni-ngx-toolsMeasurementReport-state");
	localStorage.removeItem("ni-ngx-toolsMeasurementReport-serialNumber");
	localStorage.removeItem("ni-ngx-calibrationReport-state");

	// #endregion
}
