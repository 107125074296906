/* ----------------- Start JS Document ----------------- */
var $ = jQuery.noConflict();

// Page Loader
// NOTE: switch from (window).load(function() { ... }); TO
// $(window).on('load', function() { ... });
// For JQuery 3.x
// https://stackoverflow.com/questions/37738732/jquery-3-0-url-indexof-error
$(window).on("load", function () {
	"use strict";
	$("#loader").fadeOut();
});

/*----------------------------------------------------*/
/*	Sticky Top Menu
/*----------------------------------------------------*/
$(window).on("scroll", function () {
	var topBar = $("#topMenu")[0];
	var stickyOffset = topBar.offsetTop;

	var mainNav = $(".navbar.navbar-default.navbar-top")[0];
	var navbarOffset = mainNav.clientHeight;

	if (window.pageYOffset >= stickyOffset + 10 + navbarOffset) {
		if (!$(topBar).hasClass("fixed")) {
			$(topBar)
				.stop()
				.fadeOut(0, function () {
					$(this).addClass("fixed");
				})
				.fadeIn(250);
		}
	} else {
		if ($(topBar).hasClass("fixed")) {
			$(topBar)
				.stop()
				.fadeOut(0, function () {
					$(this).removeClass("fixed");
				})
				.fadeIn(450);
		}
	}
});

// NOTE: The commented-out code snippet below is deprecated
// $(document).ready(function ($) {
$(function ($) {
	"use strict";

	/*---------------------------------------------------*/
	/* Progress Bar
    /*---------------------------------------------------*/

	$(".skill-shortcode").appear(
		function () {
			$(".progress").each(function () {
				$(".progress-bar").css("width", function () {
					return $(this).attr("data-percentage") + "%";
				});
			});
		},
		{ accY: -100 }
	);

	/*--------------------------------------------------*/
	/* Counter
    /*--------------------------------------------------*/

	$(".timer").countTo();

	$(".counter-item").appear(
		function () {
			$(".timer").countTo();
		},
		{ accY: -100 }
	);

	/*----------------------------------------------------*/
	/*	Nav Menu & Search
	/*----------------------------------------------------*/

	$(".nav > li:has(ul)").addClass("drop");
	$(".nav > li.drop > ul").addClass("dropdown");
	$(".nav > li.drop > ul.dropdown ul").addClass("sup-dropdown");

	$(".navbar-toggle").on("click", function () {
		$(".navbar-collapse").slideToggle();
	});

	/*----------------------------------------------------*/
	/*	Css3 Transition
	/*----------------------------------------------------*/

	if (window.innerWidth > 991) {
		$("[data-animation]").each(function () {
			var $animationName = $(this).attr("data-animation"),
				$animationDelay = "delay-" + $(this).attr("data-animation-delay");
			$(this).appear(function () {
				$(this).addClass("animated").addClass($animationName);
				$(this).addClass("animated").addClass($animationDelay);
			});
		});
	}

	/*----------------------------------------------------*/
	/*	Animation Progress Bars
	/*----------------------------------------------------*/

	$("[data-progress-animation]").each(function () {
		var $this = $(this);

		$this.appear(
			function () {
				var delay = $this.attr("data-appear-animation-delay")
					? $this.attr("data-appear-animation-delay")
					: 1;

				if (delay > 1) $this.css("animation-delay", delay + "ms");

				setTimeout(function () {
					$this.animate({ width: $this.attr("data-progress-animation") }, 800);
				}, delay);
			},
			{ accX: 0, accY: -50 }
		);
	});

	/*----------------------------------------------------*/
	/*	Milestone Counter
	/*----------------------------------------------------*/

	jQuery(".milestone-block").each(function () {
		jQuery(this).appear(
			function () {
				var $endNum = parseInt(jQuery(this).find(".milestone-number").text());
				jQuery(this).find(".milestone-number").countTo({
					from: 0,
					to: $endNum,
					speed: 4000,
					refreshInterval: 60
				});
			},
			{ accX: 0, accY: 0 }
		);
	});

	/*----------------------------------------------------*/
	/*	Mailing List
	/*----------------------------------------------------*/

	// TODO: Re-implement this if we decided to add a mailing list. Do not use ajax request here

	// $("#subscribe").click(function () {
	// 	$.ajax({
	// 		url: "/AddEmail?emailAddress=" + $("#email_address").val(),
	// 		method: "POST",
	// 		success: function (data) {
	// 			console.info(data);
	// 			$("#email_address").val("");
	// 			alertify.notify("You've been added to our mailing list!")
	// 		},
	// 		error: function (err) {
	// 			console.warn(err);
	// 			if (err.responseText.indexOf("409") > -1) {
	// 				alertify.warning("We already have you in our mailing list!");
	// 			} else {
	// 				alertify.warning("Something went wrong!")
	// 			}

	// 		}
	// 	})

	// })
});

/* ----------------- Google Analytics ----------------- */
var googleTag = "G-YL4FB2RNGZ";

(function (tag) {
	window.dataLayer = window.dataLayer || [];
	window.gtag =
		window.gtag ||
		function () {
			window.dataLayer.push(arguments);
		};
	window.gtag("js", new Date());
	window.gtag("config", tag);

	var script = document.createElement("script");
	script.type = "text/javascript";
	script.async = true;
	script.src = "//www.googletagmanager.com/gtag/js?id=" + googleTag;

	document.getElementsByTagName("head")[0].appendChild(script);

	window.gtag("config", tag, {
		cookie_domain: "auto",
		cookie_flags: "secure;samesite=lax;",
		page_path: location.pathname
	});
	window.gtag("event", "page_view");
})(googleTag);
