(window as any).jQuery = (window as any).$ = require("jquery");

// Style imports for the Home bundle - Pre-login pages.
import "./css/icons.scss";
import "./css/slider.scss";
import "../Global/css/net-inspect.scss";
import "./css/style.scss";
import "./css/mobile.scss";
import "./css/select2.scss";

// JS imports for the Home bundle - Pre-login pages.
import "jquery";
import "bootstrap";
import "alertifyjs";
import "./js/jquery.appear";
import "./js/select2.min";
import "./js/count-to";
import "./js/slider";
import "./js/script";
import "./js/login";
import "./js/contact-us";
import "./js/map-box-custom-map";
import "./js/customer";
import "./js/wsso";
