/**
 * jQuery functions for the Contact Us page
 */
(function ($) {
	$(document).ready(function () {
		// #region Tab Navigation Logic

		/**
		 * Javascript to enable hash linking to tabs.
		 * Get the hash from the URL, format the jQuery querySelector string,
		 * and show the tab.
		 */
		var hash = document.location.hash;
		if (hash) {
			// Format the selector string
			var formattedSelectorString = `#contact-tabs a[href*='${hash}']`;
			$(formattedSelectorString).tab("show");
		}

		/**
		 * jQuery to handle changing the hash for page-reloads
		 */
		$('#contact-tabs a[data-toggle="tab"]').on("show.bs.tab", function (e) {
			window.location.hash = e.target.hash;
		});

		/**
		 * When sales inquiries are submitted, call the google conversion report function.
		 */
		$("#sales-inquiries #submit_btn").click(function () {
			if (goog_report_conversion) {
				goog_report_conversion();
			}
			return true;
		});

		/**
		 * NI5BETA-7673
		 * When sales inquiries interests change, call the toggleNumEmployees handler function.
		 */
		$("#sales-inquiries #SalesInterests").change(toggleNumEmployeesVisibility);

		/**
		 * NI5BETA-7673
		 * Handler to toggle the visibility of the Number of Employees input accordinging to whether the
		 * "Sales Quote" checkbox is checked.
		 */
		function toggleNumEmployeesVisibility() {
			var show = false;
			$('#sales-inquiries [name="Interests"]:checked').each(function () {
				if (this.value === "Sales Quote") {
					show = true;
				}
			});

			if (show) {
				$("#salesNumEmp").show();
			} else {
				$("#salesNumEmp").hide();
			}
		}

		// #endregion

		// #region Training Inquiries Tab

		/**
		 * jQuery listener for the Training Inquiries tab "You are interested in" checkboxes.
		 * When a checkbox is selected, the toggleLocationVisibility callback function is called
		 * to appropriately show / hide the "Location" input.
		 */
		$('[name="Interests"]').change(toggleLocationVisibility);

		/**
		 * Handler function for showing / hiding the location input field on the Training Inqueries Tab
		 * form based on whether the selected checkbox value is equal to Instructor-Led Classroom Training OR
		 * On-Site Training (i.e for when a location needs to be provided).
		 */
		function toggleLocationVisibility() {
			var show = false;
			$('[name="Interests"]:checked').each(function () {
				if (
					this.value === "Instructor-led Classroom Training" ||
					this.value === "On-Site Training"
				) {
					show = true;
				}
			});

			if (show) {
				$("#location").show();
			} else {
				$("#location").hide();
			}
		}

		/**
		 * Triggers / calls the above function for showing / hiding the location input field.
		 */
		toggleLocationVisibility();

		// #endregion

		// #region Request an Account Tab

		/**
		 * Handle showing / hiding specific form controls based on the hasAccount attribute value (on the Request an Account tab).
		 * Activates the label (and de-activates the other), then toggles showing / hiding of the accountForm controls based on
		 * whether all of the inputs should be shown or a subset, determined by the CSS classes "form-group all" (for new companies)
		 * AND "form-group" (w/o the all class for companies that are already NI customers).
		 *
		 * PREVIOUS:
		 * ().change(function () {
		 *		$("#switcher label").removeClass("active").addClass("inactive");
		 * 		$(this).closest("label").addClass("active").removeClass("inactive");
		 *
		 * 		if (this.value === "false") {
		 * 			$(".accountForm .form-group").show();
		 * 		} else {
		 * 			$(".accountForm .form-group").hide();
		 * 			$(".accountForm .form-group.all").show();
		 * 		}
		 * });
		 *
		 * NI5BETA-7673
		 * Show all form inputs; remove the ability to select from two different request an account forms.
		 */
		$(".accountForm .form-group.all").show();

		/**
		 * NI5BETA-8328
		 * Ensure the the user has provided 2 unique email addresses; Otherwise, prevent them from submitting the form
		 */
		$("#QualityManagerEmail").change(validateEmails);
		$("#AdditionalUserEmail").change(validateEmails);

		/**
		 * Callback to check that the emails entered are different from eachother and that both are provided.
		 * Acts as additional form validation and provides a clear warning to the user if any criteria is not met.
		 */
		function validateEmails() {
			// Get the two email inputs by their unique id attributes
			var qmEmailValue = $("#QualityManagerEmail").val(),
				additionalEmailValue = $("#AdditionalUserEmail").val();

			// Only perform validation once both inputs have values
			if (
				!qmEmailValue ||
				qmEmailValue == "" ||
				!additionalEmailValue ||
				additionalEmailValue == ""
			) {
				return;
			}

			// Error message element to add after the new `note` section if the emails are not unique or provided
			var errorMsg =
				"<span class='p-danger email-err-msg' id='emailErr'>Email addresses must be different from each other</span>";

			// If values are provided and they are unique from eachother, enable the submit button
			if (
				qmEmailValue &&
				additionalEmailValue &&
				qmEmailValue != additionalEmailValue &&
				qmEmailValue != "" &&
				additionalEmailValue != ""
			) {
				// Remove the disabled class from the submit button
				$("#submit_btn").removeClass("disabled");

				// Remove the error message from each of the email address inputs
				$(".email-help-text").each(function () {
					$("#emailErr").remove();
				});
			} else {
				// Add the disabled class to the submit button
				$("#submit_btn").addClass("disabled");

				// Add the error message element to the DOM after the `note` span element
				$(".email-help-text").each(function () {
					// Only add the email error message span ONCE
					if (!$(this.parentElement).has(errorMsg).get(0)) {
						$(this).after(errorMsg);
					}
				});
			}
		}

		/**
		 * Select2 - the following code takes the <select> DOM element with a class of CustomerName
		 * and changes it to a multi-select drop-down picker using the Select2 (https://select2.org/) library.
		 *
		 * The change event is fired immediately when the user makes a selection.
		 * Check if the selected value is "Other" and if so, insert a new input allowing the session user to provide
		 * a name for the "Other" customer.
		 */
		$(".CustomerName")
			.select2({
				width: "resolve", // need to override the changed default
				placeholder: "Select a customer"
			})
			.change(function () {
				// console.log($(this).val())

				var otherCustomerInputTemplate =
						"<input class='form-control' style='margin-top:15px;' size='50' type='text' name='CustomerName' id='addCustName' placeholder='Enter Customer Name Here (Required)' required />",
					collinsBizUnitTemplate = `<select class='biz-unit form-control' style='margin-top:15px;' name='BusinessUnit' id='addBizUnit' data-placeholder='Select a Collins Aerospace Business Unit' required>
											<option class='biz-unit-options' value='Collins Aerospace - Actuation Systems'>Collins Aerospace - Actuation Systems</option>
											<option class='biz-unit-options' value='Collins Aerospace - Aerostructures'>Collins Aerospace - Aerostructures</option>
											<option class='biz-unit-options' value='Collins Aerospace - Avionics'>Collins Aerospace - Avionics</option>
											<option class='biz-unit-options' value='Collins Aerospace - Global Ops'>Collins Aerospace - Global Ops</option>
											<option class='biz-unit-options' value='Collins Aerospace - Interiors'>Collins Aerospace - Interiors</option>
											<option class='biz-unit-options' value='Collins Aerospace - Landing Systems'>Collins Aerospace - Landing Systems</option>
											<option class='biz-unit-options' value='Collins Aerospace - Mission Systems'>Collins Aerospace - Mission Systems</option>
											<option class='biz-unit-options' value='Collins Aerospace - Power & Controls'>Collins Aerospace - Power & Controls</option>
											<option class='biz-unit-options' value='Collins Aerospace - Propeller Systems'>Collins Aerospace - Propeller Systems</option>
										</select>`;

				if ($(this).val()) {
					if (
						$(this).val().includes("Other") &&
						$(this).val().includes("Collins Aerospace")
					) {
						if ($("#addCustName").length < 1) {
							$(this).parent().append(otherCustomerInputTemplate);
						}

						if ($("#addBizUnit").length < 1) {
							$(this).parent().append(collinsBizUnitTemplate);
						}
					} else if (
						$(this).val().includes("Other") &&
						!$(this).val().includes("Collins Aerospace")
					) {
						$("#addBizUnit").remove();

						if ($("#addCustName").length < 1) {
							$(this).parent().append(otherCustomerInputTemplate);
						}
					} else if (
						$(this).val().includes("Collins Aerospace") &&
						!$(this).val().includes("Other")
					) {
						$("#addCustName").remove();

						if ($("#addBizUnit").length < 1) {
							$(this).parent().append(collinsBizUnitTemplate);
						}
					} else {
						$("#addCustName").remove();
						$("#addBizUnit").remove();
					}
				} else {
					$("#addCustName").remove();
					$("#addBizUnit").remove();
				}
			});

		// #endregion

		onSubmitSales = function (token) {
			var form = document.getElementById("sales-form");
			var submitButton = document.getElementById("submit-sales");
			if (form.checkValidity()) {
				// Disable the button
				setTimeout(() => (submitButton.disabled = true));
				form.submit();
			} else {
				grecaptcha.reset();
				form.reportValidity();
			}
		};

		onTrainingFormSubmit = function (token) {
			var form = document.getElementById("training-form");
			var submitButton = document.getElementById("submit-training");
			if (form.checkValidity()) {
				// Disable the button
				setTimeout(() => (submitButton.disabled = true));
				form.submit();
			} else {
				grecaptcha.reset();
				form.reportValidity();
			}
		};

		onSuggestionsFormSubmit = function (token) {
			var form = document.getElementById("suggestions-form");
			var submitButton = document.getElementById("submit-suggestions");
			if (form.checkValidity()) {
				// Disable the button
				setTimeout(() => (submitButton.disabled = true));
				form.submit();
			} else {
				grecaptcha.reset();
				form.reportValidity();
			}
		};

		// #region Google Code for Sales Inquiries Submit Button Conversion Page

		goog_snippet_vars = function () {
			var w = window;
			w.google_conversion_id = 962631381;
			w.google_conversion_label = "hEChCNze710Q1a2CywM";
			w.google_remarketing_only = false;
		};
		// DO NOT CHANGE THE CODE BELOW.
		goog_report_conversion = function (url) {
			goog_snippet_vars();
			window.google_conversion_format = "3";
			window.google_is_call = true;
			var opt = new Object();
			opt.onload_callback = function () {
				if (typeof url != "undefined") {
					window.location = url;
				}
			};
			var conv_handler = window["google_trackConversion"];
			if (typeof conv_handler == "function") {
				conv_handler(opt);
			}
		};

		// #endregion
	});
})(jQuery);
