var mapboxgl = require("mapbox-gl/dist/mapbox-gl.js");

/**
 * jQuery function to create / request map box data for the About Us pre-login page
 * NOTE: csp's changed to support this:
 * 1. connect-src - added <add source="*.mapbox.com" />
 * 2. child-src - added <add source="blob:" />
 * 3. style-src - added <add source="api.mapbox.com" />
 */
(function($) {
	$.fn.createMap = function() {
		// Get the current path to make sure that this only runs for the About Us page
		var pathName = window.location.pathname;

		if (pathName && pathName == "/about/") {
			// Set the access token to use for retrieving mapbox resources
			mapboxgl.accessToken =
				"pk.eyJ1IjoibmV0LWluc3BlY3QiLCJhIjoiY2puNG1uM2E1MDM4aDNydGR4enN3cmpjZCJ9.ZpFwx-M1AjLQn88h1oaKFw";

			// Create the map
			var map = new mapboxgl.Map({
				container: "map",
				style: "mapbox://styles/mapbox/light-v10",
				center: [-122.209041, 47.676396],
				zoom: 12
			});

			// create DOM element for the marker
			var el = document.createElement("div");
			el.id = "marker";

			// create the marker
			new mapboxgl.Marker().setLngLat([-122.209041, 47.676396]).addTo(map);
		}
	};

	// Call the custom shuffle function once the page loads
	$(document).ready(function() {
		$("#map").createMap();
	});
})(jQuery);
